import * as React from "react"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import MenuList from "@mui/material/MenuList"
import Item, { ItemType } from "./Item"
import { Box, Button, Divider, Tooltip, css, styled } from "@mui/material"

export type DropdownMenuProps = {
  label: JSX.Element
  listItems: ItemType[]
  size: "small" | "medium"
  triggerGtmEvent?: boolean
  changeUiLanguage?: (newLanguage: "en-GB" | "cy") => void
  tooltip?: string
  disabled?: boolean
}

const DropdownMenu = ({
  label,
  listItems,
  size,
  triggerGtmEvent,
  changeUiLanguage,
  tooltip,
  disabled,
}: DropdownMenuProps) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <Tooltip title={tooltip || ""}>
        <span>
          <Button
            id="basic-button"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleToggle}
            component={"a"}
            sx={{
              fontWeight: open ? 700 : 500,
            }}
            ref={anchorRef}
            disabled={disabled || false}
          >
            {label}
          </Button>
        </span>
      </Tooltip>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ borderRadius: "1rem" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <PopperBox
                  component="div"
                  aria-labelledby="basic-button"
                  sx={{
                    marginRight: { sm: "0.625rem", lg: 0 },
                    maxWidth: size === "small" ? "10rem" : "25rem",
                  }}
                >
                  <MenuList
                    id="split-button-menu"
                    autoFocusItem
                    sx={{ padding: 0 }}
                  >
                    {listItems
                      .filter((listItem) => !listItem.hide)
                      .map((listItem, index) => [
                        <Item
                          key={index}
                          label={listItem.label}
                          link={listItem.link}
                          external={listItem.external}
                          children={listItem.children}
                          triggerGtmEvent={triggerGtmEvent}
                          changeUiLanguage={changeUiLanguage}
                          handleMenuToggle={handleToggle}
                        />,
                        index + 1 < listItems.length && (
                          <Divider
                            key={`divider-${index}`}
                            sx={{
                              marginX: "1rem",
                              marginY: "0 !important",
                            }}
                            aria-hidden="true"
                          />
                        ),
                      ])}
                  </MenuList>
                </PopperBox>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
export default DropdownMenu

const PopperBox = styled(Box)`
  ${({ theme }) => css`
    border-radius: ${theme.boxBorderRadius};
    background-color: ${theme.palette.white.main};
    color: ${theme.palette.primary.main};
  `}
`
