import React, { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  css,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useSchemeSuppliersData } from "./SchemSupplierContext"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Supplier } from "../../../graphqGenaretedTypes"
import Loader from "../../shared/Loader"
import FullListPopUp from "./FullListPopUp"
import { useTranslation } from "react-i18next"
type CountySuppliers = {
  county: string
  suppliers: Supplier[]
}[]

export const SupplierAccordion = function () {
  const [popUpOpen, setPopUpOpen] = useState(false)
  const { t, ready } = useTranslation("fullListBikeShop")
  const [clickedSupplier, setClickedSupplier] = useState<Supplier>({})

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const { data } = useSchemeSuppliersData({})
  const onlineSuppliers = data.suppliers.filter((supplier) => supplier.isOnline)
  const rawCountySuppliers = data.suppliers.filter(
    (supplier) => !supplier.isOnline
  )
  let countySuppliers: CountySuppliers = []

  rawCountySuppliers.forEach((supplier) => {
    const index = countySuppliers.findIndex(
      (countySupplier) => countySupplier.county === supplier.county
    )
    if (index !== -1) {
      countySuppliers[index].suppliers = [
        ...countySuppliers[index].suppliers,
        supplier,
      ]
    } else {
      countySuppliers = countySuppliers.concat([
        { county: supplier.county || "", suppliers: [supplier] },
      ])
    }
  })

  countySuppliers.sort((a, b) => {
    if (a.county < b.county) {
      return -1
    }
    if (a.county > b.county) {
      return 1
    }
    return 0
  })

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const handleClick = (item: Supplier) => {
    setPopUpOpen(true)
    setClickedSupplier(item)
  }

  const accordion = (contySuppliers: CountySuppliers) => (
    <>
      {contySuppliers.map((countySupplier) => (
        <StyledAccordion
          expanded={expanded === countySupplier.county}
          onChange={handleChange(countySupplier.county)}
          data-cy="full-list-county"
        >
          <StyledAccordionSummary
            expandIcon={<StyledArrow />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontWeight: expanded === countySupplier.county ? "bold" : "auto",
            }}
          >
            {countySupplier.county}
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {countySupplier.suppliers.map((item) => (
              <ShopLink onClick={() => handleClick(item)}>{item.name}</ShopLink>
            ))}
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
      <FullListPopUp
        open={popUpOpen}
        setOpen={setPopUpOpen}
        supplier={clickedSupplier}
      />
    </>
  )

  const onlineSupplierAccordion = (
    <StyledAccordion
      sx={{ marginBottom: "1.875rem" }}
      expanded={expanded === "online"}
      onChange={handleChange("online")}
    >
      <StyledAccordionSummary
        expandIcon={<StyledArrow />}
        aria-controls="online-content"
        id="online-header"
        sx={{
          color: "#92237F",
          fontWeight: expanded === "online" ? "bold" : "auto",
        }}
      >
        {t("fullListBikeShop.onlineSuppliers")}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {onlineSuppliers.map((item) => (
          <ShopLink onClick={() => handleClick(item)}>{item.name}</ShopLink>
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  )

  return (
    <>
      {data.loading || !ready ? (
        <Loader />
      ) : (
        <>
          {isDesktop && onlineSupplierAccordion}
          <StyledGrid
            container
            spacing={7}
            justifyContent={{ xs: "center", md: "flex-start" }}
            data-cy="full-list-outer-container"
          >
            <Grid item>
              {!isDesktop && onlineSupplierAccordion}
              {accordion(
                countySuppliers.slice(0, Math.ceil(countySuppliers.length / 2))
              )}
            </Grid>

            <Grid item>
              {accordion(
                countySuppliers.slice(Math.ceil(countySuppliers.length / 2))
              )}
            </Grid>
          </StyledGrid>
        </>
      )}
    </>
  )
}

const StyledGrid = styled(Grid)`
  cursor: pointer;
`

const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    width: auto;
    margin: 0 1.25rem;
    box-shadow: ${theme.boxShadow};
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 33.5rem;
      margin-left: 0;
      margin-right: 0;
    }
  `
)

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => css`
    height: 3.5rem;
    color: ${theme.palette.primary.main};
    padding-left: 1rem;
  `
)

const StyledArrow = styled(ExpandMoreIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
  `
)

const ShopLink = styled(Button)`
  display: block;
  line-height: 1.1875rem;
  margin: 1rem 0;
  padding: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`

const StyledAccordionDetails = styled(AccordionDetails)`
  margin-top: -1.1875rem;
  padding-left: 1rem;
`
