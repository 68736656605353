import { useMediaQuery, useTheme } from "@mui/material"
import { Box, Link } from "@mui/material"
import { useParams } from "react-router-dom"

export type LogosectionProps = {
  logo: string
  organisationName: string | undefined
}

export default function Condensed_Logosection({
  logo,
  organisationName,
}: LogosectionProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()

  return (
    <>
      <Box
        component="div"
        sx={{ height: "3.463rem", display: "table" }}
        data-cy="header-logo-condensed"
      >
        <Link
          component={Link}
          href={`/organisations/${params.organisationId}/employee/dashboard`}
          sx={{
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          <Box
            component="img"
            sx={{
              maxWidth: "7.625rem",
              maxHeight: isDesktop ? "3.125rem" : "2.737rem",
              paddingLeft: { xs: 3, sm: 3, md: 6 },
              paddingBottom: 1,
            }}
            alt={
              organisationName !== undefined
                ? `${organisationName} logo`
                : "Vivup logo"
            }
            src={logo}
          ></Box>
        </Link>
      </Box>
    </>
  )
}
