import { useMediaQuery, useTheme } from "@mui/material"
import ButtonChat from "../shared/button-chat/ButtonChat"
import background from "./assets/background.png"
import { useState, useEffect } from "react"

const Layout = ({
  billboardImage,
  children,
}: {
  billboardImage?: string
  children: JSX.Element
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [isShrunk, setShrunk] = useState(false)

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50)
        ) {
          return true
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false
        }

        return isShrunk
      })
    }

    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [])

  const getContainerStyle = (): React.CSSProperties => {
    const desktopBackgroundPosition = isShrunk
      ? "center 3.463rem"
      : "center 6.25rem"
    return {
      backgroundImage: `url(${billboardImage || background})`,
      backgroundSize: isDesktop ? "100% 100%" : "cover",
      backgroundPosition: isDesktop ? desktopBackgroundPosition : "center",
      minHeight: "100vh",
      overflow: "hidden",
      color: "white",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
    }
  }

  return (
    <div>
      <div style={getContainerStyle()} id="main-content">
        {children}
      </div>
      <ButtonChat />
    </div>
  )
}

export default Layout
