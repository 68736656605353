import { styled, Button as MuiButton, css } from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { HealthAndWellbeing } from "../../../../graphqGenaretedTypes"
import {
  ButtonBase,
  WhiteButtonText,
  SpaceTwoButtonContainer,
} from "../../shared/SharedStyles"
import { useRegisterInterestMutation } from "../../../../graphqGenaretedTypes"
import Alert from "../../../shared/Alert"
import { EAP_RELATIVE_URL } from "../../../../data/constants"
import { gtmPortalHomepageHealthandWellbeingButtonClick } from "../../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

export const Button = ({ data }: { data: HealthAndWellbeing }) => {
  const { link } = data
  if (link) {
    return (
      <SpaceTwoButtonContainer>
        <ButtonContent data={data} />
      </SpaceTwoButtonContainer>
    )
  }
  return (
    <SpaceTwoButtonContainer component={"div"}>
      <ButtonContent data={data} />
    </SpaceTwoButtonContainer>
  )
}

function ButtonContent({ data }: { data: HealthAndWellbeing }) {
  const { t, ready } = useTranslation("portalHomepage")

  function getButtonText(level: string) {
    if (!ready) {
      return null
    }
    if (level === "disabled") {
      return t("healthAndWellbeing.buttons.requestToOpen")
    }
    if (level === "level_1" || level === "level_2") {
      return t("healthAndWellbeing.buttons.healthAndWellbeing")
    }
    if (level === "level_3" || level === "level_4" || level === "level_5") {
      return t("healthAndWellbeing.buttons.yourCare")
    }
  }

  const { level, link } = data
  const textToRender = level && getButtonText(level)
  const { organisationId } = useParams()

  const [isClicked, setIsClicked] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState("")
  const [registerInterestMutation] = useRegisterInterestMutation({
    variables: {
      schemeTypeRelativeUrl: EAP_RELATIVE_URL,
      organisationId: organisationId ? organisationId : "",
    },
  })

  const handleClick = () => {
    if (level === "disabled") {
      registerInterestMutation().then(({ data }) => {
        if (
          data?.registerInterest?.errors &&
          data?.registerInterest?.errors.length > 0
        ) {
          setMessage(t("healthAndWellbeing.messages.unsuccessful"))
          setIsSuccess(false)
        } else {
          setMessage(t("healthAndWellbeing.messages.success"))
          setIsSuccess(true)
        }
      })
      setIsClicked(true)
    }
    gtmPortalHomepageHealthandWellbeingButtonClick()
  }
  return (
    <>
      {message !== "" && (
        <Alert
          severity={!isSuccess ? "error" : "success"}
          message={message || ""}
        />
      )}
      <GreenButtonContainer
        onClick={handleClick}
        disabled={isClicked}
        component={"a"}
        href={link || undefined}
        disableFocusRipple
        target="_blank"
        rel="noopener"
      >
        <WhiteButtonText>{textToRender}</WhiteButtonText>
        <span className="screen-reader-only">
          {t("healthAndWellbeing.screenReader")}
        </span>
      </GreenButtonContainer>
    </>
  )
}

const GreenButtonContainer = styled(ButtonBase)`
  ${({ theme }) => css`
    background-color: #488200;
    display: inline-flex;

    &:hover {
      background-color: #488200;
    }

    &:disabled {
      background-color: ${theme.palette.grey[200]};
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0;
    }
  `}
` as typeof MuiButton
