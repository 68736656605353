import {
  MainHeader,
  PropositionSpaceTwoSubHeader,
  Space2HeaderMobilePadding,
  Space2HeaderDesktopPadding,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { HealthAndWellbeing } from "../../../../graphqGenaretedTypes"
import { Button } from "./Button"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

export const Header = ({ data }: { data: HealthAndWellbeing }) => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <Space2HeaderMobilePadding>
        <Content data={data} />
      </Space2HeaderMobilePadding>
    )
  }
  return (
    <Space2HeaderDesktopPadding>
      <Content data={data} />
    </Space2HeaderDesktopPadding>
  )
}

function Content({ data }: { data: HealthAndWellbeing }) {
  const { level } = data
  const isMobile = useIsMobileView()
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <>
      <MainHeader variant="h3" align="left">
        {level === "level_3" || level === "level_4"
          ? t("healthAndWellbeing.yourCareHeader")
          : t("healthAndWellbeing.healthAndWellbeingHeader")}
      </MainHeader>
      <PropositionSpaceTwoSubHeader variant="h4" align="left">
        {level === "level_5"
          ? t("healthAndWellbeing.subHeaders.nonEAPHeader")
          : t("healthAndWellbeing.subHeaders.EAPHeader")}
      </PropositionSpaceTwoSubHeader>
      {!isMobile && level !== "hidden" && (
        <Box sx={{ marginTop: "38px" }}>
          <Button data={data} />
        </Box>
      )}
    </>
  )
}
