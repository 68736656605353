import Footer from "./footer/Footer"
import Header from "./header/Header"
import {
  GlobalLayoutContext,
  useGlobalLayoutData,
  useMenuData,
} from "./GlobalLayoutContext"
import MegaMenu from "../shared/global-mega-menu/MegaMenu"
import { Outlet } from "react-router"
import BurgerMenu from "../shared/nav/BurgerMenu"
import { StepMenuProps } from "../shared/step-menu/StepMenu"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DummyMegaMenu from "../shared/DummyMegaMenu"

import { ThemeProvider } from "@mui/material"
import { useParams } from "react-router-dom"
import C2WTheme from "../../theme/mui-c2w-theme"
import DefaultTheme from "../../theme/mui-theme"
export type globalHeaderData = {
  loading?: boolean
}

export const GlobalLayout = function ({
  setShouldShowCookiePopup,
}: {
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { t, ready } = useTranslation("layout")
  const params = useParams()
  const contextValue = useGlobalLayoutData()
  const layoutLoading = contextValue.loading

  const [shouldFetchMenuData, setShouldFetchMenuData] = useState(false)
  const { data: menuData, loading: menuDataLoading } =
    useMenuData(shouldFetchMenuData)

  useEffect(() => {
    if (!layoutLoading) {
      setShouldFetchMenuData(true)
    }
  }, [layoutLoading])

  const dummyBurgerMenuHierarchy =
    contextValue.dummyMegaMenu.length > 0
      ? contextValue.dummyMegaMenu.map(({ label, link }) => ({
          key: t("menu." + label),
          name: t("menu." + label),
          link,
        }))
      : []

  const stepMenuProps: StepMenuProps = {
    title: t("menu.yourBenefits"),
    itemHierarchy:
      !shouldFetchMenuData || menuDataLoading
        ? dummyBurgerMenuHierarchy
        : menuData?.burgerMenuItems ?? [],
    levels: !shouldFetchMenuData || menuDataLoading ? 1 : 3,
  }

  useEffect(() => {
    const url = location.href
    const payrollGiving = location.href.includes("payroll_giving")
    let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
    if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
      subdomain = "Vivup"
    }

    document.title = payrollGiving
      ? "Payroll Giving"
      : `${subdomain}: Portal Homepage`
  }, [])

  if (!ready) {
    return null
  }
  return (
    <ThemeProvider
      theme={
        ["payroll_giving"].includes(params.schemeType || "")
          ? C2WTheme
          : DefaultTheme
      }
    >
      <GlobalLayoutContext.Provider value={contextValue}>
        <>
          <Header
            logo={contextValue.logo}
            organisationName={contextValue.organisationName}
            condensedLogo={contextValue.condensedLogo}
            currentUserId={contextValue.currentUserId}
            showBasket={false}
            showNoticeboard={contextValue.noticeboards}
            backendUser={contextValue.backendUser}
            reporter={contextValue.reporter}
            isOrganisationSettingUser={contextValue.isOrganisationSettingUser}
            employeeOrganisations={contextValue.employeeOrganisations}
            MegaMenu={
              !menuDataLoading && menuData ? (
                <MegaMenu
                  items={menuData.megaMenuItems}
                  healthAndWellbeing={menuData.healthAndWellbeing}
                  familyCareItem={menuData.familyCare}
                  familyPayItem={menuData.familyPay}
                />
              ) : (
                <DummyMegaMenu dummyMegaMenu={contextValue.dummyMegaMenu} />
              )
            }
            BurgerMenu={<BurgerMenu stepMenuProps={stepMenuProps} />}
            availableLanguages={contextValue.availableLanguages}
            defaultLanguage={contextValue.defaultLanguage}
          />
          <div className="global-main-container" id="main-content">
            <Outlet />
          </div>
          <Footer
            privacyNoticeUrl={contextValue.privacyNoticeUrl}
            organisationPrivacyNotice={contextValue.organisationPrivacyNotice}
            setShouldShowCookiePopup={setShouldShowCookiePopup}
          />
        </>
      </GlobalLayoutContext.Provider>
    </ThemeProvider>
  )
}
