import Layout from "../Layout"
import { useSchemeSuppliersData } from "./SchemSupplierContext"
import { Box, Container, Link, styled, Typography, css } from "@mui/material"
import useIsMobileView from "../../../utils/useIsMobileView"
import { SupplierAccordion } from "./SupplierAccordion"
import decathlonLogo from "../../shared/assets/decathlonLogo.png"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

const linkToForm =
  "https://forms.office.com/Pages/ResponsePage.aspx?id=GUc3PXaEOUmxxaZPfDDrIxWMW5LPHQNJsJ43ZMxCqRpUQ0M3NUZBRFJPRVhLOUROM1NNQzlSVURIMi4u"

const FullListOfBikeShops = () => {
  const isMobile = useIsMobileView()
  const { data } = useSchemeSuppliersData({})
  const params = useParams()
  const { t, ready } = useTranslation("fullListBikeShop")

  if (!ready) {
    return null
  }
  return (
    <Layout
      breadcrumbsItems={data.bikeShopsListBreadcrumbs}
      bannerTitle={t("fullListBikeShop.bannerTitle")}
    >
      <TextContainer data-cy="full-list-info">
        <Header variant="h2">{t("fullListBikeShop.mainTitle")}</Header>
        {isMobile ? (
          <InstructionText>
            {t("fullListBikeShop.contentPartOnes")}
            {t("fullListBikeShop.contentPartTwo")}
            <TextWithLink
              component="a"
              href={linkToForm}
              target="_blank"
              rel="noopener"
            >
              {t("fullListBikeShop.textWithLink")}
              <span className="screen-reader-only">
                ({t("fullListBikeShop.openInNewTab")})
              </span>
            </TextWithLink>{" "}
            {t("fullListBikeShop.contentPartThree")}
            <div style={{ marginBottom: "2rem", marginTop: "1rem" }}>
              <div>
                <DecathlonLogo src={decathlonLogo} alt={"DecathlonLogo"} />
              </div>
              <div style={{ fontWeight: "bold", color: "#3643BA" }}>
                Looking for Decathlon?
              </div>
              <div style={{ color: "#3643BA" }}>
                Discover our wide range of Decathlon products{" "}
                <strong>
                  <Link
                    href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/departments/${data.bikeDepartmentId}`}
                    rel="noopener"
                    component={"a"}
                    style={{
                      color: "#3643BA",
                      textDecorationColor: "3643BA !important",
                    }}
                  >
                    here
                    <span className="screen-reader-only">
                      (opens in a new tab)
                    </span>
                  </Link>
                </strong>
              </div>
            </div>
          </InstructionText>
        ) : (
          <>
            <InstructionText>
              {t("fullListBikeShop.contentPartOnes")}
            </InstructionText>
            <InstructionText>
              {t("fullListBikeShop.contentPartTwo")}{" "}
              <TextWithLink
                component="a"
                href={linkToForm}
                target="_blank"
                rel="noopener"
              >
                {t("fullListBikeShop.textWithLink")}
                <span className="screen-reader-only">
                  ({t("fullListBikeShop.openInNewTab")})
                </span>
              </TextWithLink>{" "}
              {t("fullListBikeShop.contentPartThree")}
              <div
                style={{
                  marginBottom: "2rem",
                  display: "flex",
                  marginTop: "3rem",
                }}
              >
                <div>
                  <DecathlonLogo src={decathlonLogo} alt={"DecathlonLogo"} />
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <div style={{ fontWeight: "bold", color: "#3643BA" }}>
                    Looking for Decathlon?
                  </div>
                  <div style={{ color: "#3643BA" }}>
                    Discover our wide range of Decathlon products{" "}
                    <strong>
                      <Link
                        href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/departments/${data.bikeDepartmentId}`}
                        component={"a"}
                        rel="noopener"
                        style={{
                          color: "#3643BA",
                          textDecorationColor: "3643BA !important",
                        }}
                      >
                        here
                        <span className="screen-reader-only">
                          (opens in a new tab)
                        </span>
                      </Link>
                    </strong>
                  </div>
                </div>
              </div>
            </InstructionText>
          </>
        )}
      </TextContainer>
      <Container sx={{ padding: { md: "3.125rem 7rem", xs: "3.125rem 0" } }}>
        <SupplierAccordion />
      </Container>
    </Layout>
  )
}

export default FullListOfBikeShops

const TextContainer = styled(Box)`
  ${({ theme }) => css`
    width: 72%;
    margin: 0 auto;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
      max-width: 1212px;
      margin: unset;
      margin-left: 2rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      margin-left: auto;
      margin-right: auto;
    }
  `}
`
const DecathlonLogo = styled("img")`
  max-width: 170px;
  width: 100%;
  height: 34px;
`
const Header = styled(Typography)`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.5rem;
      text-align: left;
      margin-bottom: 18px;
    }
  `}
`

const InstructionText = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
    color: ${theme.palette.primary.main};
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      text-align: left;
      font-size: 1rem;
      line-height: 1.25rem;
    }
  `}
`

const TextWithLink = styled(Link)`
  display: inline-block;
  color: #92237f;
` as typeof Link
