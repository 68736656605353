import { useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { AnimatePresence, motion } from "framer-motion"
import classNames from "classnames"
import { Typography, ListItem, List, Button, styled, css } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material"
import ClickAwayListener from "@mui/material/ClickAwayListener"

export type colorDropdownProps = {
  paramName: string
  selected: string
  dropdownOptions: {
    title: string
    value: string
    color?: string
    borderRequired?: boolean
  }[]
  selectedSizeFnc?: (size: string | undefined) => void
}

function ColorDropdown({
  selected,
  dropdownOptions,
  selectedSizeFnc,
}: colorDropdownProps) {
  const [expanded, setExpanded] = useState(false)
  const [selectedOption, setSelectedOption] = useState(selected)
  const selectedItem = dropdownOptions
    .filter((item) => item?.value == selectedOption)
    .map((op) => op)[0]
  const selectedColorCode = selectedItem?.color || dropdownOptions[0]?.color
  const selectedValue = selectedItem?.value || dropdownOptions[0]?.value
  const selectedTitle = selectedItem?.title || dropdownOptions[0]?.title
  const selectedBorderRequired =
    selectedItem?.borderRequired || dropdownOptions[0]?.borderRequired

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  if (selectedSizeFnc) {
    selectedSizeFnc(selectedValue)
  }

  const applyDropdownFilters = (value: string) => {
    setExpanded(!expanded)
    setSelectedOption(value)
    if (selectedSizeFnc) {
      selectedSizeFnc(value)
    }
  }

  const handleClick =
    (value: string) => (event: React.KeyboardEvent | React.MouseEvent) => {
      event.preventDefault()
      applyDropdownFilters(value)
    }

  const onKeyUp = (value: string) => (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      applyDropdownFilters(value)
    }
  }

  const listContents = (
    <List className="ColorDropdown-ul">
      {dropdownOptions
        .filter((item) => item?.value != selectedValue)
        .map((item, index) => {
          {
            return (
              <ListItem
                key={index}
                className="ColorDropdown_links"
                role="list"
                tabIndex={0}
                onClick={handleClick(item.value)}
                onKeyUp={onKeyUp(item.value)}
              >
                <div className="colorDropdown">
                  {item.color && (
                    <div
                      className={`colorContainer ${
                        item.borderRequired && "colorBorderRequired"
                      }`}
                      style={{ background: item.color }}
                    ></div>
                  )}
                  {item.title}
                </div>
              </ListItem>
            )
          }
        })}
    </List>
  )

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <div className="ColorDropdownContainer">
        <div className="ColorDropdownBox">
          <div className="ColorDropdown">
            <ColorDropDownButton onClick={() => setExpanded(!expanded)}>
              <div className="ColorDropdown-box__title">
                <ColorDropDownTitle variant="body1">
                  <div className="colorDropdown">
                    {selectedColorCode && (
                      <div
                        className={`colorContainer ${
                          selectedBorderRequired && "colorBorderRequired"
                        }`}
                        style={{ background: selectedColorCode }}
                      ></div>
                    )}
                    {selectedTitle}
                  </div>
                </ColorDropDownTitle>
                <ArrowDropDownIcon
                  className={classNames("ColorDropdown-box__arrow", {
                    "ColorDropdown-box__arrow--expanded": expanded,
                  })}
                  style={{
                    height: isDesktop ? "1em" : "0.79em",
                    width: isDesktop ? "1em" : "0.79em",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </div>
            </ColorDropDownButton>
            <AnimatePresence>
              {expanded && (
                <motion.div
                  className="ColorDropdown-box__content"
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{
                    type: "spring",
                    bounce: 0,
                    duration: 0.3,
                  }}
                >
                  {listContents}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default ColorDropdown

const ColorDropDownButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 0rem 0.75rem;
    height: 2rem;
    text-transform: capitalize;
    min-width: 3.0625rem;
    color: ${theme.palette.primary.main};

    @media and (min-width: ${theme.breakpoints.values.md}px) {
      height: 2.4375rem;
    }
  `}
`

const ColorDropDownTitle = styled(Typography)`
  ${({ theme }) => css`
    margin: 0;
    font-size: 0.75rem;
    @media and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.063rem;
    }
  `}
`
