import { object } from "yup"
import { placeOfWorkValidation } from "../../../../utils/yup-validation-schema/placeOfWorkValidation"
import { employeeNumberValidation } from "../../../../utils/yup-validation-schema/employeeNumberValidation"
import { niNumberValidation } from "../../../../utils/yup-validation-schema/niNumberValidation"
import {
  firstNameValidation,
  lastNameValidation,
} from "../../../../utils/yup-validation-schema/nameValidation"
import { mobileNumberValidationRequired } from "../../../../utils/yup-validation-schema/mobileNumberValidation"
import { phoneNumberValidation } from "../../../../utils/yup-validation-schema/phoneNumerValidation"
import { customEmailValidation } from "../../../../utils/yup-validation-schema/emailValidation"
import { titleValidation } from "../../../../utils/yup-validation-schema/titleValidation"
import { dateOfBirthValidation } from "../../../../utils/yup-validation-schema/dateOfBirthValidation"

export const yourDetailsValidationSchema = object().shape({
  placeOfWork: placeOfWorkValidation,
  employeeNumber: employeeNumberValidation,
  niNumber: niNumberValidation,
  lastName: lastNameValidation,
  firstName: firstNameValidation,
  mobileNumber: mobileNumberValidationRequired,
  phoneNumber: phoneNumberValidation,
  email: customEmailValidation,
  title: titleValidation,
  dateOfBirth: dateOfBirthValidation,
})
