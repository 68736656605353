import { string } from "yup"
import i18n from "../../i18n"

export const typeOfBikeValidation = string()
  .when("brandOfBike", {
    is: (brandOfBike: string) => brandOfBike && brandOfBike.length > 0,
    then: string().required(() =>
      i18n.t("bikeQuote:validations.typeOfBikeEmptyWarning")
    ),
  })
  .when("bikeValue", {
    is: (bikeValue: number) => bikeValue && bikeValue > 0,
    then: string().required(() =>
      i18n.t("bikeQuote:validations.typeOfBikeEmptyWarning")
    ),
  })
