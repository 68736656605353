import { styled, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

export const MyOrderButton = ({ storeName }: { storeName: string }) => {
  const { t, ready } = useTranslation("checkoutPage")

  if (!ready) {
    return null
  }
  if (storeName === "Home and Electronics") {
    return (
      <DefaultOrderButton variant="gradient">
        {t("checkoutPage.thankYou.myOrder")}
      </DefaultOrderButton>
    )
  }
  return (
    <C2WMyOrderButton>{t("checkoutPage.thankYou.myOrder")}</C2WMyOrderButton>
  )
}
const DefaultOrderButton = styled(Button)`
  width: 19.125rem;
  height: 3.125rem;
  border-radius: 2.625rem;
  box-shadow: ${({ theme }) => theme.boxShadow};
  opacity: 1;
`
const C2WMyOrderButton = styled(DefaultOrderButton)`
  background: linear-gradient(
    90deg,
    #872074,
    ${({ theme }) => theme.palette.primary.main}
  );
  color: white;
`
