import { createContext } from "react"
import { useGetInvitationConfirmDataQuery } from "../../../graphqGenaretedTypes"
import { useSearchParams } from "react-router-dom"

export type RegConfirmContextData = {
  email: string
  requiresDomainValidation: boolean
  salaryBrackets: {
    id: number
    description: string
  }[]
  mobileNumberState: "required" | "optional" | "hidden"
  salaryBracketState: "required" | "optional" | "hidden"
}

const defaultValue: RegConfirmContextData = {
  email: "",
  requiresDomainValidation: false,
  salaryBrackets: [],
  mobileNumberState: "optional",
  salaryBracketState: "optional",
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const useRegConfirmData: () => {
  data: RegConfirmContextData
} = function () {
  const [searchParams] = useSearchParams()

  let invitationToken = searchParams.get("invitation_token")
  let tokenType = "invitation_token"

  if (!invitationToken) {
    invitationToken = searchParams.get("e")
    tokenType = "mapp_token"
  }

  const data = useGetInvitationConfirmDataQuery({
    variables: {
      invitationToken:
        searchParams.get("invitation_token") || searchParams.get("e") || "",
      tokenType: tokenType,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      email: data.data?.invitedUser?.email,
      requiresDomainValidation:
        data.data?.invitedUser?.employeeOrganisations?.[0]
          .requiresDomainValidation || false,
      salaryBrackets: data.data?.salaryBrackets || [],
      salaryBracketState:
        data.data?.invitedUser?.employeeOrganisations?.[0].salaryBracketState ||
        "optional",
      mobileNumberState:
        data.data?.invitedUser?.employeeOrganisations?.[0].mobileNumberState ||
        "optional",
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}

export const RegConfirmContext = createContext(defaultValue)
