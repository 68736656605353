import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import FooterLogoSection from "./sections/FooterLogoSection"
import FooterLowerSection from "./sections/FooterLowerSection"
import FooterLinksSection from "./sections/FooterLinksSection"
import { useTheme } from "@mui/material/styles"

const Footer = ({
  privacyNoticeUrl,
  setShouldShowCookiePopup,
  organisationPrivacyNotice = false,
}: {
  privacyNoticeUrl?: string
  organisationPrivacyNotice?: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const theme = useTheme()
  if (
    navigator.userAgent.includes("wv") ||
    navigator.userAgent.includes("WebView") ||
    (navigator.userAgent.includes("AppleWebKit") &&
      !navigator.userAgent.includes("Safari"))
  ) {
    return null
  } else {
    return (
      <footer>
        <Box
          className="Footer"
          sx={{
            backgroundColor: theme.palette.primary.main,
            paddingTop: "7px",
            paddingBottom: "7px",
            paddingLeft: { xs: 2, sm: 5, md: 2 },
            paddingRight: { xs: 2, sm: 5, md: 2 },
            color: theme.palette.white.main,
          }}
          data-cy="footer"
        >
          <Container>
            <Grid
              item
              container
              xs={12}
              lg={10}
              sx={{
                marginX: "auto",
              }}
            >
              <FooterLogoSection />

              <FooterLinksSection
                privacyNoticeUrl={privacyNoticeUrl}
                organisationPrivacyNotice={organisationPrivacyNotice}
                setShouldShowCookiePopup={setShouldShowCookiePopup}
              />

              <FooterLowerSection />
            </Grid>
          </Container>
        </Box>
      </footer>
    )
  }
}

export default Footer
