import i18n from "../i18n"
export const getStorePriceTooltip = (quoteType = "NHS") =>
  i18n.t("constants:constants.StorePriceTooltip", {
    quoteType: quoteType,
  })
export const STORE_PRICE_TOOLTIP_NON_NHS =
  "The figures quoted are after savings applied. Actual savings depend on individual tax situation. Figures are a guide only."
export const DISPOSAL_FEE_TOOLTIP =
  "Disposal fee is a one-time deduction taken at the end of the scheme period. VAT will be added at the appropriate rate at the time the disposal fee is taken. "
export const END_OF_AGREEMENT_FEE_TOOLTIP =
  "End of Agreement fee is a one-time fee deduction taken at the end of the scheme period. It is necessary to ensure that the transfer is not treated as a taxable benefit to you under HMRC rules. The amount shown includes VAT at the applicable rate."
export const MAX_BASKET_QTY = 20
export const MINIMUM_WAGE = 11.44
export const MONTHS_PER_YEAR = 12
export const TAX_SAVING = 0.2
export const NI_SAVING = 0.12
export const CPI = 0.031
export const NHS = "NHS"
export const TEACHERS_PENSION = "Teachers' Pension"
export const PG_ABOVE_CPI = (quoteType: string) => {
  if (quoteType === "NHS") return 0.015
  else if (quoteType === "Teachers' Pension") return 0.016
  else return 0
}

export const SALARY_BRACKETS = [
  "£0 to £12,500",
  "£12,501 to £50,270",
  "£50,271 to £150,000",
  "Over £150,000",
]

export const SIGN_IN_PATH = "/users/sign_in"
export const EXPIRED_PASSWORD_PATH = "/users/password_expired"
export const ROOT_PATH = "/"
export const PORTAL_PATH_WITH_SALARY_BRACKET = (
  organisationId: string,
  schemeType: string
) =>
  `/organisations/${organisationId}/employee/dashboard?requireSalaryBracket=true&schemeType=${schemeType}`
export const FOUR_OH_FOR_PATH = "/404"

export const EAP_RELATIVE_URL = "employee_assistance_provision"
