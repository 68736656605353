import { useParams, useSearchParams } from "react-router-dom"
import CheckboxItem from "./CheckboxItem"
import updatedSearchParams from "../../../utils/updatedSearchParams"
import { useState } from "react"
import { Button, List } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { motion } from "framer-motion"
import { gtmStoreFilter } from "../../shared/gtm-events/CustomGtmEvents"

export type CheckboxListProps = {
  items: ReadonlyArray<string>
  paramName: string
  initialExpandedState?: boolean
  showCounts?: boolean
}

export default function Item({
  items,
  paramName,
  initialExpandedState = false,
}: CheckboxListProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [expanded, setExpanded] = useState(initialExpandedState)
  const activeItems = searchParams.getAll(paramName)
  const initialItems = !initialExpandedState ? items.slice(0, 5) : items
  const params = useParams()
  const store = params.schemeType
  const createdCheckboxItem = (item: string, index: number) => (
    <CheckboxItem
      text={item}
      value={item}
      checked={activeItems.includes(item)}
      key={index}
      onChange={(state, value) => {
        if (state === true) {
          setSearchParams(
            updatedSearchParams(searchParams, [
              { paramName, value: [value, ...activeItems] },
            ])
          )
          gtmStoreFilter({
            selectedOption: value,
            filterType: paramName,
            store: store,
          })
        } else {
          setSearchParams(
            updatedSearchParams(searchParams, [
              {
                paramName,
                value: activeItems.filter((item) => item !== value),
              },
            ])
          )
        }
      }}
      originalText={""}
      isLifestylingStore={false}
    />
  )

  return (
    <List sx={{ marginBottom: 0 }}>
      {initialItems.map((item, index) => createdCheckboxItem(item, index))}
      {!initialExpandedState && (
        <>
          {items.length > 5 && expanded ? (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              transition={{
                type: "spring",
                bounce: 0,
                duration: 0.3,
              }}
            >
              {items
                .slice(5)
                .map((item, index) => createdCheckboxItem(item, index))}
            </motion.div>
          ) : (
            <>
              {items.length > 5 && (
                <div className="more-button-container">
                  <Button
                    color="secondary"
                    onClick={() => setExpanded(true)}
                    sx={{ ":hover": { backgroundColor: "inherit" } }}
                  >
                    <ArrowDropDownIcon />
                    More
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </List>
  )
}
