import { useState } from "react"
const ASSET_BUCKET = window.ENV["ASSET_BUCKET"]

export const useBuildAwsImageUrl = (locale: string, imageName: string) => {
  const [fetchValue, setFetchValue] = useState("")

  if (!imageName) {
    return ""
  }

  validateUrl(locale, imageName).then((response) => {
    setFetchValue(response)
    return response
  })

  return fetchValue
}

const validateUrl = (locale: string, imageName: string) => {
  let imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/${locale}/${imageName}`

  return fetch(imageUrl)
    .then((response) => {
      if (response.ok) {
        imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/${locale}/${imageName}`
      } else {
        imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/en-GB/${imageName}`
      }
      return imageUrl
    })
    .catch(() => {
      imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/en-GB/${imageName}`
      return imageUrl
    })
}
