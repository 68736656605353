import classNames from "classnames"
import tabImage from "./tab.png"

type TabProp = {
  active: boolean
  setActive: (state: boolean) => void
  children: React.ReactNode
}

const Tab = ({ active, setActive, children }: TabProp) => {
  return (
    <>
      <div
        className={classNames("mega-menu-tab", { "d-none": !active })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        {/* children is a React Element with either one or two columns */}
        {children}
        <img
          src={tabImage}
          alt="browseCategories"
          className="mega-menu-tab__image"
        />
      </div>
      <div
        className={classNames("mega-menu-tab__background", {
          "d-none": !active,
        })}
      />
    </>
  )
}

export default Tab
