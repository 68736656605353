import React from "react"
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { styled, Typography } from "@mui/material"
import { styleVars } from "../../../style"
import {
  HealthAndWellbeingButton,
  ContentContainer,
} from "../global-mega-menu/HealthAndWellbeingDesktopCard"
import { gtmPortalHomepageHealthAndWellbeingLinkClick } from "../gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
import { t } from "i18next"

export type SlideProps = {
  name?: string
  HealthAndWellBeingLevel?: string | null
  customLink?: string | null
  handleLink: (customLink: string, external: boolean) => void
}
export type SlideCardProps = {
  name?: string
  HealthAndWellBeingLevel?: string | null
  customLink?: string | null
  handleLink: (customLink: string, external: boolean) => void
  eapNumber?: string | null
}
const PlatformNameHeading = ({
  name,
  HealthAndWellBeingLevel,
  customLink,
  handleLink,
}: SlideProps) => {
  return (
    <div>
      {name === "healthAndWellbeing" &&
      !(
        HealthAndWellBeingLevel === "disabled" ||
        HealthAndWellBeingLevel === "hidden"
      ) ? (
        <TittleLink
          className="mega-menu-tab__category mega-menu-tab__title"
          style={{
            paddingLeft: "0px",
            fontWeight: "bold",
          }}
          onClick={(event) => {
            const labelText = event.currentTarget.textContent
            const clickLocation = "Header Mega Menu"

            handleLink(customLink, true)
            gtmPortalHomepageHealthAndWellbeingLinkClick(
              labelText,
              customLink,
              clickLocation
            )
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#302557",
              fontWeight: "bold",
            }}
          >
            {HealthAndWellBeingLevel === "level_1" &&
              t("menu.exploreHealthAndWellbeing")}
            {(HealthAndWellBeingLevel === "level_3" ||
              HealthAndWellBeingLevel === "level_4") &&
              t("menu.yourCareWellbeingPlatform")}
            {HealthAndWellBeingLevel === "level_2" &&
              t("menu.exploreHealthAndWellbeing")}
          </div>
          <ArrowForwardIcon
            fontSize="inherit"
            className="mega-menu-tab__forward-arrow"
            sx={{
              color: "#35185f",
            }}
          />
        </TittleLink>
      ) : null}
    </div>
  )
}
const MobileCard = ({
  name,
  eapNumber,
  customLink,
  handleLink,
  HealthAndWellBeingLevel,
}: SlideCardProps) => {
  const { t, i18n, ready } = useTranslation("layout")
  const mobile_img_not_available = useBuildAwsImageUrl(
    i18n.language,
    "mobile_img_not_available.png"
  )
  const your_care = useBuildAwsImageUrl(i18n.language, "your_care.png")
  const DEFAULT_TEL = "03303 800658*"
  const DEFAULT_FOOTER = t("menu.healthAndWellbeingFooter")

  if (!ready) {
    return null
  }
  return (
    <div>
      {(name === "healthAndWellbeing" &&
        HealthAndWellBeingLevel === "hidden") ||
      HealthAndWellBeingLevel === "disabled" ? (
        <>
          <NotAvailableImage
            src={mobile_img_not_available}
            alt="HealthAndWellbeing"
          />
        </>
      ) : null}
      {(name === "healthAndWellbeing" &&
        HealthAndWellBeingLevel === "level_1") ||
      HealthAndWellBeingLevel === "level_2" ? (
        <div style={{ textAlign: "center", marginTop: "28px" }}>
          <ContentContainer style={{ textAlign: "center" }}>
            <ContentContainerHeader variant="h4">
              {t("menu.needSupport")}
            </ContentContainerHeader>
            {
              <TelContainer>
                {!eapNumber ? DEFAULT_TEL : eapNumber}
              </TelContainer>
            }
            {!eapNumber && <FooterContainer>{DEFAULT_FOOTER}</FooterContainer>}
          </ContentContainer>
          <ExploreButton onClick={() => handleLink(customLink, true)}>
            {t("menu.exploreHealthAndWellbeing")}
          </ExploreButton>
        </div>
      ) : null}
      {name === "healthAndWellbeing" &&
      (HealthAndWellBeingLevel === "level_3" ||
        HealthAndWellBeingLevel === "level_4" ||
        HealthAndWellBeingLevel === "level_5") ? (
        <div style={{ textAlign: "center" }}>
          <img
            src={your_care}
            alt="your care platform"
            style={{
              paddingBottom: "1rem",
              paddingTop: "22px",
              width: HealthAndWellBeingLevel === "level_5" ? "125px" : "100px",
              height: HealthAndWellBeingLevel === "level_5" ? "85px" : "68px",
            }}
          />
          {HealthAndWellBeingLevel !== "level_5" && (
            <ContentContainer style={{ textAlign: "center" }}>
              <ContentContainerHeader variant="h4">
                {t("menu.needSupport")}
              </ContentContainerHeader>
              {
                <TelContainer>
                  {!eapNumber ? DEFAULT_TEL : eapNumber}
                </TelContainer>
              }
              {!eapNumber && (
                <FooterContainer>{DEFAULT_FOOTER}</FooterContainer>
              )}
            </ContentContainer>
          )}
          <ExploreButton onClick={() => handleLink(customLink, true)}>
            {t("menu.yourCareWellbeingPlatform")}
          </ExploreButton>
        </div>
      ) : null}
    </div>
  )
}
export { PlatformNameHeading, MobileCard }
const TittleLink = styled("div")`
  &:hover {
    background-color: transparent;
  }
`
const NotAvailableImage = styled("img")`
  display: block;
  margin: 2rem auto;
  width: 69.9%;
  max-width: 300px;
`
export const ContentContainerHeader = styled(Typography)`
  text-align: center;

  font-size: 15px;
  line-height: 40px;
  @media screen and (max-width: 372px) {
    font-size: 15px;
  }
`
export const TelContainer = styled(Typography)`
  && {
    font-size: 38px;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #488200;
    margin: 8px auto;
    text-align: center;
    @media screen and (max-width: 372px) {
      font-size: 29px;
      margin: 0px auto;
    }
  }
`
export const FooterContainer = styled(Typography)`
  font-size: 9.2px;
  line-height: 13px;
  color: ${styleVars.primary};
  width: 62.9%;
  text-align: center;
  margin: 0 auto;
  padding-top: 0.7rem;
  @media screen and (max-width: 372px) {
    font-size: 7.2px;
    padding-top: 0.5rem;
    line-height: 9px;
  }
`
const ExploreButton = styled(HealthAndWellbeingButton)`
  && {
    max-width: 283.15px;
    width: 100%;
  }
`
