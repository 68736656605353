import { Formik, FormikHelpers } from "formik"
import * as Yup from "yup"
import { emailValidation } from "../../../utils/yup-validation-schema/emailValidation"
import {
  StyledForm,
  FormContainer,
  SubmitButton,
  RedOuterBox,
  RedInnerBox,
  WarningParagraph,
} from "../shared/mainPageStyles"
import { Headers } from "./Headers"
import { getEmailField } from "../../cycle-to-work/quote-page/fields/yourDetailsFields"
import { useContext, useState } from "react"
import GenericField from "../../shared/form/GenericField"
import PasswordField from "../sign-up/PasswordField"
import { getPasswordField } from "../sign-up/fields"
import { useTranslation } from "react-i18next"
import { Link } from "@mui/material"
import useIsMobileView from "../../../utils/useIsMobileView"
import TroubleLoggingPopup from "./TroubleLoggingPopup"
import { Box, Typography, css, styled } from "@mui/material"
import Layout from "../Layout"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { LogoSection } from "../LogoSection"
import i18n from "../../../i18n"
import { useUserSignInMutation } from "../../../graphqGenaretedTypes"
import Alert from "../../shared/Alert"
import LoggedOutBanner from "../LoggedOutBanner"
import { RegistrationLayoutContext } from "../RegistrationLayoutContext"
import { getShouldShowBanner } from "../registrationUtils"
import Loader from "../../shared/Loader"

type SignInFormikForm = {
  email: string
  password: string
}

const initialValues: SignInFormikForm = {
  email: "",
  password: "",
}

const MAX_NUM_ATTEMPTS = 3

const SignIn = () => {
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const [numOfAttempts, setNumOfAttempts] = useState<number | undefined>()
  const { t, ready } = useTranslation(["regForm", "bikeQuote"]) // email and password need these namespaces
  const [showPassword, setShowPassword] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const isMobile = useIsMobileView()
  const [troubleEmail, setTroubleEmail] = useState("")
  const [userSignInMutation] = useUserSignInMutation()
  const url = location.href
  const subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
  const {
    billboardImage,
    leftLogo,
    logo,
    rightLogo,
    content,
    header,
    stopRegistration,
    showBanner: portalProviderShowBanner,
    banner,
    loading,
  } = useContext(RegistrationLayoutContext)

  const globalShowBanner = banner?.show

  const handleModalClose = () => {
    setOpenModal(false)
  }
  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const toggleShowPassword = () => {
    setShowPassword((prev) => {
      return !prev
    })
  }

  const validationSchema = Yup.object().shape({
    email: emailValidation,
    password: Yup.string().required(() =>
      i18n.t("regForm:password.requiredValidationError")
    ),
  })

  const handleSubmit = async (
    values: SignInFormikForm,
    formikHelpers: FormikHelpers<SignInFormikForm>
  ) => {
    const { data } = await userSignInMutation({
      variables: {
        email: values.email,
        password: values.password,
      },
    })

    const errors = data?.userLogin?.errors
    const failedAttempts = data?.userLogin?.user?.failedAttempts

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount(errorCount + 1)

      if (failedAttempts) {
        setNumOfAttempts(MAX_NUM_ATTEMPTS - failedAttempts)
      }
    } else {
      const authToken = data?.userLogin?.authToken

      if (authToken) {
        const queryString = new URLSearchParams({
          auth_token: authToken,
        }).toString()

        window.location.href = `/users/authorize_user?${queryString}`
      }
    }

    formikHelpers.setSubmitting(false)
  }

  const shouldShowBanner = getShouldShowBanner(
    globalShowBanner,
    portalProviderShowBanner
  )

  if (!ready) {
    return null
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Layout billboardImage={billboardImage}>
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) =>
                  handleSubmit(values, formikHelpers)
                }
              >
                {(formik) => {
                  return (
                    <StyledForm>
                      {alertMessage && (
                        <Alert
                          severity={alertSeverity}
                          message={alertMessage}
                          key={errorCount}
                        />
                      )}
                      <FormContainer>
                        <LogoSection logos={[leftLogo, logo, rightLogo]} />
                        <Headers content={content} header={header} />
                        {subdomain !== "uea" && (
                          <>
                            {numOfAttempts !== undefined &&
                              numOfAttempts <= MAX_NUM_ATTEMPTS && (
                                <RedOuterBox data-cy="sign-in-warning-container">
                                  <RedInnerBox>
                                    <InfoIcon />

                                    <TextBox>
                                      <WarningHeader variant="h6">
                                        {numOfAttempts > 0
                                          ? t("regForm:signIn.warningHeader", {
                                              numOfAttempts: numOfAttempts,
                                            })
                                          : t("regForm:signIn.lockedHeader")}
                                      </WarningHeader>
                                      <WarningParagraph
                                        variant="body2"
                                        data-cy="sign-in-warning-text"
                                      >
                                        {numOfAttempts > 0
                                          ? t("regForm:signIn.warningText")
                                          : t("regForm:signIn.lockedText")}
                                      </WarningParagraph>
                                    </TextBox>
                                  </RedInnerBox>
                                </RedOuterBox>
                              )}
                            <GenericField
                              textFieldProps={getEmailField()}
                              inlineTextFieldProps={{
                                onChange: (e) => {
                                  formik.handleChange(e)
                                  setTroubleEmail(e.target.value)
                                },
                                onKeyDown: (e) => {
                                  if (e.key === "Enter") {
                                    formik.handleSubmit()
                                  }
                                },
                              }}
                            />
                            <PasswordField
                              passwordFieldProps={getPasswordField()}
                              showPassword={showPassword}
                              toggleShowPassword={toggleShowPassword}
                              inlineTextFieldProps={{
                                onKeyDown: (e) => {
                                  if (e.key === "Enter") {
                                    formik.handleSubmit()
                                  }
                                },
                              }}
                            />
                            <SignInButton
                              variant="gradient"
                              onClick={formik.submitForm}
                              disabled={
                                formik.isSubmitting || formik.isValidating
                              }
                              data-cy="sign-in-btn"
                            >
                              {t("regForm:signIn.signIn")}
                            </SignInButton>
                            <TextWithLinkContainer sx={{ textAlign: "center" }}>
                              <TextWithLink
                                component={"button"}
                                type="button"
                                aria-label={t("regForm:signIn.havingTrouble")}
                                onClick={handleModalOpen}
                                sx={{
                                  fontSize: !isMobile ? "1rem" : "0.75rem",
                                }}
                              >
                                {t("regForm:signIn.havingTrouble")}
                              </TextWithLink>
                            </TextWithLinkContainer>
                            {!stopRegistration && (
                              <>
                                <RegisterBox>
                                  <TextContainer>
                                    <NotRegisterBold>
                                      {t("regForm:notRegisterBold")}{" "}
                                    </NotRegisterBold>
                                    <NotRegister>
                                      {t("regForm:createAnAccount")}
                                    </NotRegister>
                                  </TextContainer>
                                </RegisterBox>
                                <RegisterButton
                                  variant="gray"
                                  href="/users/sign_up"
                                >
                                  {t("regForm:register")}
                                </RegisterButton>
                              </>
                            )}
                          </>
                        )}
                      </FormContainer>
                    </StyledForm>
                  )
                }}
              </Formik>
              {shouldShowBanner && banner?.heading && banner?.images && (
                <LoggedOutBanner
                  bannerHeading={banner.heading}
                  images={banner.images}
                />
              )}
            </>
          </Layout>
          <TroubleLoggingPopup
            openModal={openModal}
            handleModalClose={handleModalClose}
            troubleEmail={troubleEmail}
            setAlertMessage={setAlertMessage}
            setErrorCount={setErrorCount}
            setAlertSeverity={setAlertSeverity}
          />
        </>
      )}
    </>
  )
}

export default SignIn

const RegisterButton = styled(SubmitButton)`
  margin-top: 1.125rem;
  margin-bottom: 1rem;
  max-width: 280px;
  text-align: center;
`

const RegisterBox = styled(Box)`
  margin-top: 3rem;
`

const TextContainer = styled(Box)`
  text-align: center;
`

const NotRegister = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    display: inline;
    color: ${theme.palette.primary.main};
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  `}
`

const NotRegisterBold = styled(NotRegister)`
  font-weight: 700;
`

const SignInButton = styled(SubmitButton)`
  ${({ theme }) => css`
    margin-top: -0.125rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 1.5rem;
    }
  `}
`
export const TextWithLink = styled(Link)`
  && {
    text-decoration: underline;
    vertical-align: top;
    font-family: Raleway;
  }
` as typeof Link

export const TextWithLinkContainer = styled("div")`
  ${({ theme }) => css`
    margin-top: 1.875rem;
    font-size: 0.75rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 2rem;
      font-size: 0.875rem;
    }
  `}
`

const InfoIcon = styled(InfoOutlinedIcon)`
  ${({ theme }) => css`
    padding-top: 18px;
    padding-right: 6px;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      padding-right: 12px;
    }
  `}
`

const TextBox = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 14px 8px 14px 0;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 75%;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: 17px 0;
      width: 430px;
    }
  `}
`

const WarningHeader = styled(WarningParagraph)`
  ${({ theme }) => css`
    font-weight: 700;
    margin-bottom: 0.25rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
    }
  `}
`
