import Grid from "@mui/material/Grid"
import Twitter from "../images/Twitter.svg"
import Facebook from "../images/Facebook.svg"
import Instagram from "../images/Instagram.svg"
import Linkedin from "../images/Linkedin.svg"
import useIsMobileView from "../../../../utils/useIsMobileView"

const FooterIcons = () => {
  const isMobile = useIsMobileView()

  return (
    <div>
      {isMobile ? (
        <Grid>
          <a
            color="inherit"
            href="https://twitter.com/wearevivup"
            className="Links"
            target="_blank"
            rel="noopener"
            style={{ fontSize: "0.875rem" }}
          >
            <img
              src={Twitter}
              alt="Twitter"
              className="footericons_icons"
              style={{
                width: "2rem",
                height: "2rem",
                margin: "0.17rem",
              }}
            />
            <span className="screen-reader-only">(opens in a new tab)</span>
          </a>

          <a
            color="inherit"
            href="https://www.facebook.com/wearevivup"
            target="_blank"
            rel="noopener"
          >
            <img
              src={Facebook}
              alt="Facebook"
              className="footericons_icons"
              style={{
                width: "2rem",
                height: "2rem",
                margin: "0.17rem",
              }}
            />
            <span className="screen-reader-only">(opens in a new tab)</span>
          </a>

          <a
            color="inherit"
            href="https://www.instagram.com/wearevivup"
            target="_blank"
            rel="noopener"
          >
            <img
              src={Instagram}
              alt="Instagram"
              className="footericons_icons"
              style={{
                width: "2rem",
                height: "2rem",
                margin: "0.17rem",
              }}
            />
            <span className="screen-reader-only">(opens in a new tab)</span>
          </a>

          <a
            color="inherit"
            href="https://www.linkedin.com/company/vivup"
            target="_blank"
            rel="noopener"
          >
            <img
              src={Linkedin}
              alt="LinkedIn"
              className="footericons_icons"
              style={{
                width: "2rem",
                height: "2rem",
                margin: "0.17rem",
              }}
            />
            <span className="screen-reader-only">(opens in a new tab)</span>
          </a>
        </Grid>
      ) : (
        <>
          <Grid>
            <a
              color="inherit"
              href="https://twitter.com/wearevivup"
              target="_blank"
              rel="noopener"
            >
              <img
                src={Twitter}
                alt="Twitter"
                className="footericons_icons"
                style={{
                  width: "2.17rem",
                  height: "2.17rem",
                  margin: "0.07rem 0.17rem",
                }}
              />
              <span className="screen-reader-only">(opens in a new tab)</span>
            </a>

            <a
              color="inherit"
              href="https://www.facebook.com/wearevivup"
              target="_blank"
              rel="noopener"
            >
              <img
                src={Facebook}
                alt="Facebook"
                className="footericons_icons"
                style={{
                  width: "2.17rem",
                  height: "2.17rem",
                  margin: "0.07rem 0.17rem",
                }}
              />
              <span className="screen-reader-only">(opens in a new tab)</span>
            </a>
            <br />
            <a
              color="inherit"
              href="https://www.instagram.com/wearevivup"
              target="_blank"
              rel="noopener"
            >
              <img
                src={Instagram}
                alt="Instagram"
                className="footericons_icons"
                style={{
                  width: "2.17rem",
                  height: "2.17rem",
                  margin: "0.07rem 0.17rem",
                }}
              />
              <span className="screen-reader-only">(opens in a new tab)</span>
            </a>

            <a
              color="inherit"
              href="https://www.linkedin.com/company/vivup"
              target="_blank"
              rel="noopener"
            >
              <img
                src={Linkedin}
                alt="Linkedin"
                className="footericons_icons"
                style={{
                  width: "2.17rem",
                  height: "2.17rem",
                  margin: "0.07rem 0.17rem",
                }}
              />
              <span className="screen-reader-only">(opens in a new tab)</span>
            </a>
          </Grid>
        </>
      )}
    </div>
  )
}

export default FooterIcons
