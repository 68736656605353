import { useState } from "react"
import {
  Button,
  Box,
  Modal,
  Stack,
  Typography,
  Link,
  ClickAwayListener,
} from "@mui/material"
import ProductCarouselPopupGrid from ".././product-carousels/ProductCarouselPopupGrid"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { getPopupStyle } from "./popupStyle"
import { ProductType } from ".././product-card/ProductCard"
import { addBasketItem } from ".././basket-page/BasketContext"
import { useParams } from "react-router"
import Alert from "../../shared/Alert"
import {
  gtmAddToCart,
  checkProductImpressions,
  gtmCycleToWorkAddToCart,
} from "../../shared/gtm-events/CustomGtmEvents"
import Theme from "../../../theme/mui-theme"
import { PreBackOrderTextStyle } from "../ProductDetails"
import { useTranslation } from "react-i18next"

export type ProductPopupProps = {
  id: number
  title: string
  totalAmount: string
  brand: string
  variant: string
  category: string
  sku: string
  storeName: string
  props?: object
  products: ProductType[]
  brochureGroupId: number
  productId: number
  selectedSize: string
  department: string
  supplierName?: string
  releaseDate?: string
  status: "preOrder" | "backOrder" | "default"
  reachedMaximumQuantity?: boolean
  maximumQuantity?: number
}

export default function AddCardPopup({
  id,
  title,
  totalAmount,
  brand,
  variant,
  category,
  sku,
  storeName,
  products,
  brochureGroupId,
  productId,
  selectedSize,
  department,
  supplierName,
  releaseDate,
  status,
  reachedMaximumQuantity,
  maximumQuantity,
}: ProductPopupProps) {
  const classes = getPopupStyle(Theme)
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [monthlyPrice, setMonthlyPrice] = useState(0.0)
  const [showAlertMessage, setShowAlertMessage] = useState("")
  const params = useParams()
  const { t, ready } = useTranslation("productDetail")
  const CycletoWorkStore = params.schemeType === "cycle_to_work"
  const changeStateFromChild = (totalItems: number, monthlyPrice: number) => {
    setTotalItems(totalItems)
    setMonthlyPrice(monthlyPrice)
  }
  const handleOpen = () => {
    addBasketItemMutation()
      .then((data) => {
        const totalItems = data?.data?.addBasketItem?.basket?.count || 0
        const monthlyPrice =
          data?.data?.addBasketItem?.basket?.monthlyPrice || 0.0
        const errorMessageLength =
          data?.data?.addBasketItem?.errors?.length || 0

        setShowAlertMessage("")
        if (errorMessageLength > 0) {
          setShowAlertMessage(data?.data?.addBasketItem?.errors[0] || "")
          return setOpen(false)
        }
        setTotalItems(totalItems)
        setMonthlyPrice(monthlyPrice)
        setOpen(true)
        const productDetails = {
          id: id,
          name: title,
          totalAmount: totalAmount,
          brand: brand,
          category: category,
          variant: variant,
          sku: sku,
          storeName: storeName,
          department: department,
          isAddtoCart: true,
          supplierName: supplierName,
        }

        CycletoWorkStore && gtmCycleToWorkAddToCart(productDetails)
        !CycletoWorkStore &&
          gtmAddToCart({
            id: id,
            title: title,
            totalAmount: totalAmount,
            brand: brand,
            category: category,
            variant: variant,
            sku: sku,
            storeName: storeName,
            department: department,
            supplierName: supplierName,
          })
      })
      .catch(() => {
        // TODO - handle error
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [addBasketItemMutation] = addBasketItem(
    productId,
    brochureGroupId,
    selectedSize
  )

  if (!ready) {
    return null
  }
  return (
    <div>
      {showAlertMessage.length > 0 && (
        <Alert severity={"error"} message={showAlertMessage} />
      )}
      <Box sx={classes.addToOrderBtnContainer}>
        <Button
          sx={classes.addToOrderBtn}
          variant="gradient"
          disabled={reachedMaximumQuantity}
          onClick={handleOpen}
        >
          {t("productDetail.CardPopUp.addToOrderButton")}
        </Button>
      </Box>

      {reachedMaximumQuantity && (
        <p style={{ color: "red" }}>
          {t("productDetail.CardPopUp.reachedMaximumQuantitystep1")}
          {maximumQuantity}
          {t("productDetail.CardPopUp.reachedMaximumQuantitystep2")}.
        </p>
      )}

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ borderRadius: "0.9375rem" }}
        >
          <>
            <ClickAwayListener onClickAway={handleClose}>
              <Box
                sx={{
                  width: isDesktop ? "68.125rem" : "90%",
                  ...classes.style,
                  ...(products.length > 0 ? "" : classes.noProductsFound),
                }}
                onScroll={() => checkProductImpressions()}
              >
                <Box sx={classes.textbg}>
                  <Typography sx={classes.Textbgheading}>
                    {t("productDetail.CardPopUp.itemAddedToBasket")}
                  </Typography>
                  {isDesktop &&
                    (status === "preOrder" || status === "backOrder") && (
                      <PreBackOrderTextStyle
                        sx={{
                          maxWidth: "25.5625rem",
                          height: "2.4375rem",
                          textAlign: "center",
                          marginBottom: "0.5625rem",
                        }}
                      >
                        {status === "preOrder"
                          ? t("productDetail.CardPopUp.preOrder")
                          : t("productDetail.CardPopUp.backOrder")}
                        {releaseDate &&
                          (status === "preOrder"
                            ? t("productDetail.CardPopUp.launchDate") +
                              releaseDate
                            : t("productDetail.CardPopUp.deliveryDate") +
                              releaseDate)}
                      </PreBackOrderTextStyle>
                    )}
                  <Typography sx={classes.Textbgsubheading}>
                    {t("productDetail.CardPopUp.youHave")}{" "}
                    {totalItems > 1
                      ? totalItems + t("productDetail.CardPopUp.items")
                      : totalItems + t("productDetail.CardPopUp.item")}{" "}
                    {t("productDetail.CardPopUp.inBasket")}
                    <br />
                    {t("productDetail.CardPopUp.Total")}{" "}
                    <span
                      style={{
                        color: theme.palette.secondary.main,
                        fontWeight: "bold",
                      }}
                    >
                      {t("productDetail.StickyACButton.Modal.currency")}
                      {monthlyPrice.toFixed(2)}{" "}
                    </span>
                    {t("productDetail.perMonth")}
                  </Typography>
                  {!isDesktop &&
                    (status === "preOrder" || status === "backOrder") && (
                      <PreBackOrderTextStyle
                        sx={{
                          maxWidth: "18.5625rem",
                          height: "3.6875rem",
                          textAlign: "center",
                          marginTop: "0rem",
                          marginBottom: "1.4375rem",
                        }}
                      >
                        {status === "preOrder"
                          ? t("productDetail.CardPopUp.availablePreOrder")
                          : t("productDetail.CardPopUp.availableBackOrder")}
                        {releaseDate}
                      </PreBackOrderTextStyle>
                    )}
                  <Stack
                    direction={isDesktop ? "row" : "column-reverse"}
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Button
                      sx={classes.popupButton}
                      variant="gray"
                      onClick={handleClose}
                    >
                      {t("productDetail.CardPopUp.keepShoping")}
                    </Button>
                    <Link
                      href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/basket`}
                      underline="none"
                    >
                      <Button sx={classes.popupButton} variant="gradient">
                        {t("productDetail.CardPopUp.ContinueOrder")}
                      </Button>
                    </Link>
                  </Stack>
                </Box>
                {products.length > 0 && (
                  <Box sx={classes.Carouselbg}>
                    <Box sx={{ width: "100%" }} className="add-to-card-popup">
                      <Typography sx={classes.Carouseltext}>
                        {t("productDetail.CardPopUp.frequentlyBoughtTogether")}
                      </Typography>
                      <ProductCarouselPopupGrid
                        isFrequentlyBrought={true}
                        products={products}
                        changeStateFromChild={(totalItems, monthlyPrice) =>
                          changeStateFromChild(totalItems, monthlyPrice)
                        }
                        frequentlyBrought="frequently-brought-product-clicked"
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
          </>
        </Modal>
      </div>
    </div>
  )
}
