import { useTranslation } from "react-i18next"

import { TitleHeading, SubTitleHeading } from "../shared/headingStyles"

export function Headers() {
  const { t, ready } = useTranslation(["regForm"])
  if (!ready) {
    return null
  }
  return (
    <>
      <TitleHeading variant="h1">{t("regForm:header")}</TitleHeading>
      <SubTitleHeading sx={{ textAlign: "center" }} variant="h4">
        {t("regForm:subHeader")}
      </SubTitleHeading>
    </>
  )
}
