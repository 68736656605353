import {
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  css,
  Box,
  Link,
} from "@mui/material"
import RightArrow from "./assets/RightArrow.png"
import LocationOnFill from "./assets/LocationOnFill.png"
import ListCheck from "./assets/ListCheck.png"
import CheckCircleBlue from "./assets/CheckCircleBlue.png"
import {
  ArrowImage,
  MainContainer,
  Cardlayout,
  CardBox,
  TextContainer,
  ImageConatiner,
  ImageContentContainer,
  SectionText,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/FourSimpleSteps"
const ThreeSimpleSteps = ({ link }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <MainContainerStyle>
      <SectionText sx={{ textAlign: "center" }}>
        Spread the Cost of Care in 3 Simple Steps
      </SectionText>
      <Cardlayout>
        <CardBoxstyle
          sx={{
            height: isDesktop ? "" : "172px !important",
            paddingRight: isDesktop && "40px",
          }}
        >
          <div>
            <ImageContentContainer sx={{ paddingTop: "0rem !important" }}>
              <ImageConatiner>
                <CycleImage src={LocationOnFill} alt="LocationOnFill" />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>Find your care provider</Label>

            <TextContainer>
              Click{" "}
              <strong>
                <Link href={link} component={"a"}>
                  here
                </Link>
              </strong>{" "}
              to explore the wide range of trusted care providers available to
              you
            </TextContainer>
          </div>
        </CardBoxstyle>
        <ImageWrap>
          <div>
            <ArrowImage src={RightArrow} alt="RightArrow" />
          </div>
        </ImageWrap>
        <CardBox
          sx={{
            paddingLeft: isDesktop && "40px",
            paddingRight: isDesktop && "40px",
          }}
        >
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <BasketImage src={ListCheck} alt="ListCheck" />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>Create an Order Request</Label>
            <TextContainer>
              When you’ve decided on your care provider and budget, complete the
              Order Request form with your details
            </TextContainer>
          </div>
        </CardBox>
        <ImageWrap>
          <div>
            <ArrowImage src={RightArrow} alt="RightArrow" />
          </div>
        </ImageWrap>

        <CardBox sx={{ paddingLeft: isDesktop && "40px" }}>
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <PeopleImage src={CheckCircleBlue} alt="CheckCircleBlue" />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>Provider and employer approval</Label>
            <TextContainer>
              Once submitted, your chosen provider and employer will review your
              order for approval
            </TextContainer>
          </div>
        </CardBox>
      </Cardlayout>
    </MainContainerStyle>
  )
}
export default ThreeSimpleSteps

const CycleImage = styled("img")`
  ${({ theme }) => css`
    width: 74px;
    height: 81px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 50.6px;
      height: 54.73px;
    }
  `}
`
export const ImageWrap = styled(Box)`
  ${({ theme }) => css`
    height: 285px;
    display: block;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      display: none;
    }
  `}
`
const BasketImage = styled("img")`
  ${({ theme }) => css`
    width: 69px;
    height: 60px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 44.37px;
      height: 38.15px;
    }
  `}
`

const PeopleImage = styled("img")`
  ${({ theme }) => css`
    width: 69px;
    height: 71px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 47px;
      height: 47px;
    }
  `}
`

const MainContainerStyle = styled(MainContainer)`
  ${({ theme }) => css`
    background-color: ${theme.palette.grey[200]};
    && {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        height: 778px;
      }
    }
  `}
`

const CardBoxstyle = styled(CardBox)`
  ${({ theme }) => css`
    &&& {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 10px;
      }
    }
  `}
`

const Label = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    text-align: center;
    padding-top: 55px;
    font-weight: bold;
    font-size: 16px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 20px;
    }
  `}
`
