import React, { useState } from "react"
import ReactDOM from "react-dom"
import { motion } from "framer-motion"
import onEnter from "../../../utils/onEnter"

import DrawerItem from "../drawer/DrawerItem"
import { styled } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { PlatformNameHeading, MobileCard } from "./HealthAndWellbeingMobileCard"
import {
  FamilyCareMobileCard,
  FamilyCareNameHeading,
} from "./FamilyCareMobileCard"
import {
  FamilyPayMobileCard,
  FamilyPayNameHeading,
} from "./FamilyPayMobileCard"

export type SlideProps = {
  level: number
  featured?: boolean
  name: string
  previousLabel: string
  children?: JSX.Element[]
  setHideLevel: (hideLevel: boolean) => void
  imgUrl?: string | null
  link?: string | null
  external?: boolean
  setOpen?: (state: boolean) => void
  HealthAndWellBeingLevel?: string | null
  eapNumber?: string | null
  customLink?: string | null
  label: string[]
}

function Slide({
  level,
  name,
  featured,
  previousLabel,
  children,
  setHideLevel,
  imgUrl,
  link,
  external,
  setOpen,
  HealthAndWellBeingLevel,
  eapNumber,
  customLink,
  label,
}: SlideProps) {
  const [active, setActive] = useState(false)
  const navigate = useNavigate()

  const handleAction = (state: boolean) => {
    setHideLevel(state)
    setActive(state)
  }
  const params = useParams()
  const handleTitle = () => {
    if (link) {
      if (link.includes("family_pay")) {
        window.open(link, "_blank")
        return
      }
      if (external) {
        window.location.href = link
      } else {
        navigate(link)
      }
      if (setOpen) {
        setOpen(false)
      }
    }
  }

  const handleLink = (customLink: string, external: boolean) => {
    if (customLink.trim() !== "") {
      if (customLink.includes("family_pay")) {
        window.open(customLink, "_blank")
        return
      }
      if (external) {
        window.location.href = customLink
      } else {
        navigate(customLink)
      }
      if (setOpen) {
        setOpen(false)
      }
    }
  }

  const targetElement = document.getElementById(`step-menu-slide-${level}`)

  if (active && targetElement) {
    return ReactDOM.createPortal(
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ type: "spring", bounce: 0 }}
      >
        <DrawerItem
          text={previousLabel}
          back
          onClick={() => handleAction(false)}
          onKeyDown={(event) => onEnter(event, () => handleAction(false))}
        />

        {name === "healthAndWellbeing" && (
          <div
            onClick={() => customLink && handleLink(customLink, true)}
            onKeyDown={(event) => onEnter(event, () => handleTitle())}
            className="step-menu__title"
          >
            {name}
          </div>
        )}

        {name === "familyCare" && (
          <div
            onClick={() =>
              handleLink(
                `/organisations/${params.organisationId}/employee/benefits/family_care`,
                true
              )
            }
            onKeyDown={(event) => onEnter(event, () => handleTitle())}
            className="step-menu__title"
          >
            {name}
          </div>
        )}
        {name === "familyPay" && (
          <div
            onClick={() => handleLink(customLink || "#", true)}
            onKeyDown={(event) => onEnter(event, () => handleTitle())}
            className="step-menu__title"
          >
            {name}
          </div>
        )}

        {!["healthAndWellbeing", "familyCare", "familyPay"].includes(name) && (
          <div
            onClick={() => handleTitle()}
            onKeyDown={(event) => onEnter(event, () => handleTitle())}
            className="step-menu__title"
          >
            {name}
          </div>
        )}

        <PlatformNameHeading
          name={name}
          HealthAndWellBeingLevel={HealthAndWellBeingLevel}
          customLink={customLink}
          handleLink={handleLink}
        />
        <FamilyCareNameHeading
          name={name}
          customLink={`/organisations/${params.organisationId}/employee/benefits/family_care`}
          handleLink={handleLink}
        />
        <FamilyPayNameHeading
          name={name}
          customLink={link || "#"}
          handleLink={handleLink}
        />
        {children?.map((element, index) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ))}

        {/* to render an image underneath menu in global burger menu */}
        {["Home and Electronics", "Y Cartref ac Electroneg"].includes(name) &&
          imgUrl && (
            <HEImageContainer
              src={imgUrl}
              alt="burger-menu-home-and-electronics"
            />
          )}
        {["Cycle to Work", "Beicio i'r Gwaith"].includes(name) && imgUrl && (
          <C2WImageContainer src={imgUrl} alt="burger-menu-cycle-to-work" />
        )}
        {["Health and Wellbeing", "Iechyd a Llesiant"].includes(name) && (
          <MobileCard
            name={name}
            HealthAndWellBeingLevel={HealthAndWellBeingLevel}
            customLink={customLink}
            handleLink={handleLink}
            eapNumber={eapNumber}
          />
        )}
        {["Gofal Teulu", "Family Care"].includes(name) && (
          <FamilyCareMobileCard
            name={name}
            customLink={`/organisations/${params.organisationId}/employee/benefits/family_care`}
            handleLink={handleLink}
            label={label}
          />
        )}
        {["Tâl Teulu", "Family Pay"].includes(name) && (
          <FamilyPayMobileCard
            name={name}
            customLink={link || "#"}
            handleLink={handleLink}
            label={label}
          />
        )}
      </motion.div>,
      targetElement
    )
  } else {
    return (
      <div>
        <DrawerItem
          text={name}
          featured={featured}
          divider
          onClick={() => handleAction(true)}
          onKeyDown={(event) => onEnter(event, () => handleAction(true))}
        />
      </div>
    )
  }
}
export default Slide

const HEImageContainer = styled("img")`
  display: block;
  margin: 2rem auto;
  width: 83.8%;
  max-width: 300px;
`

const C2WImageContainer = styled(HEImageContainer)`
  width: 96.7%;
`
