import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import Carousel from "react-material-ui-carousel"
import React, { useCallback, useEffect, useState } from "react"
import { useMediaQuery, useTheme } from "@mui/material"
import { PortalBanner, Banner, Portal } from "../../graphqGenaretedTypes"
import BannerCarousel from "../shared/banner-carousel/BannerCarousel"

export type BannerCarouselGridProps = {
  banners: PortalBanner
  hifiveBanner: Banner
  show: boolean
  hi5SsoUrl?: Portal[]
}

export default function PortalHomePageBanner({
  banners,
  show,
  hi5SsoUrl,
  hifiveBanner,
}: BannerCarouselGridProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [activeChild, setActiveChild] = useState(0)

  const changeChild = useCallback((e: KeyboardEvent) => {
    if (e.key === "ArrowLeft") {
      setActiveChild((a) => (a - 1 < 0 ? 1 : a - 1))
    } else if (e.key === "ArrowRight") {
      setActiveChild((a) => (a + 1 > 1 ? 0 : a + 1))
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", changeChild)
    return function cleanup() {
      document.removeEventListener("keydown", changeChild)
    }
  })
  function renderCarousel() {
    if (hifiveBanner && hifiveBanner.__typename === "Banner") {
      return (
        <Carousel
          index={activeChild}
          autoPlay={true}
          navButtonsAlwaysVisible={false}
          navButtonsAlwaysInvisible={true}
          indicators={true}
          animation="slide"
          indicatorIconButtonProps={{ className: "Indicator" }}
          activeIndicatorIconButtonProps={{
            className: "activeIndicator",
          }}
          interval={5000}
          height={isDesktop ? "13.125rem" : "9.5rem"}
          className="carouselBanner"
        >
          <BannerCarousel
            desktopImageUrl={hifiveBanner.desktopBannerUrl || ""}
            mobileImageUrl={hifiveBanner.mobileBannerUrl}
            link={null}
            showWelcomeText={false}
            showButton={true}
            hi5SsoUrl={hi5SsoUrl}
          />
          <React.Fragment>
            <BannerCarousel
              desktopImageUrl={banners.desktopUrl}
              mobileImageUrl={banners.mobileUrl}
              welcomeText={banners.welcomeText}
              link={null}
              showWelcomeText={show}
            />
          </React.Fragment>
          <React.Fragment>
            <BannerCarousel
              link={banners.link}
              text={banners.text}
              logos={banners.logos}
              showWelcomeText={show}
            />
          </React.Fragment>
        </Carousel>
      )
    } else {
      return (
        <Carousel
          index={activeChild}
          autoPlay={true}
          navButtonsAlwaysVisible={false}
          navButtonsAlwaysInvisible={true}
          indicators={true}
          animation="slide"
          indicatorIconButtonProps={{ className: "Indicator" }}
          activeIndicatorIconButtonProps={{
            className: "activeIndicator",
          }}
          interval={5000}
          height={isDesktop ? "13.125rem" : "9.5rem"}
          className="carouselBanner"
        >
          <React.Fragment>
            <BannerCarousel
              desktopImageUrl={banners.desktopUrl}
              mobileImageUrl={banners.mobileUrl}
              welcomeText={banners.welcomeText}
              link={null}
              showWelcomeText={show}
            />
          </React.Fragment>
          <React.Fragment>
            <BannerCarousel
              link={banners.link}
              text={banners.text}
              logos={banners.logos}
              showWelcomeText={show}
            />
          </React.Fragment>
        </Carousel>
      )
    }
  }
  function renderCarouselWithDefaultBanner() {
    if (hifiveBanner && hifiveBanner.__typename === "Banner") {
      return (
        <Carousel
          index={activeChild}
          autoPlay={true}
          navButtonsAlwaysVisible={false}
          navButtonsAlwaysInvisible={true}
          indicators={true}
          animation="slide"
          indicatorIconButtonProps={{ className: "Indicator" }}
          activeIndicatorIconButtonProps={{
            className: "activeIndicator",
          }}
          interval={5000}
          height={isDesktop ? "13.125rem" : "9.5rem"}
          className="carouselBanner"
        >
          <BannerCarousel
            desktopImageUrl={hifiveBanner.desktopBannerUrl || ""}
            mobileImageUrl={hifiveBanner.mobileBannerUrl}
            link={null}
            showWelcomeText={false}
            showButton={true}
            hi5SsoUrl={hi5SsoUrl}
          />
          <React.Fragment>
            <BannerCarousel
              desktopImageUrl={banners.desktopUrl || ""}
              mobileImageUrl={banners.mobileUrl}
              welcomeText={banners.welcomeText}
              link={null}
              showWelcomeText={show}
            />
          </React.Fragment>
        </Carousel>
      )
    } else {
      return (
        <Carousel
          index={activeChild}
          autoPlay={true}
          navButtonsAlwaysVisible={false}
          navButtonsAlwaysInvisible={true}
          indicators={true}
          animation="slide"
          indicatorIconButtonProps={{ className: "Indicator" }}
          activeIndicatorIconButtonProps={{
            className: "activeIndicator",
          }}
          interval={5000}
          height={isDesktop ? "13.125rem" : "9.5rem"}
          className="carouselBanner"
        >
          <React.Fragment>
            <BannerCarousel
              desktopImageUrl={banners.desktopUrl || ""}
              mobileImageUrl={banners.mobileUrl}
              welcomeText={banners.welcomeText}
              link={null}
              showWelcomeText={show}
            />
          </React.Fragment>
        </Carousel>
      )
    }
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "#EAE9EE !important",
        }}
      >
        <Grid container spacing={""}>
          <Grid item xs={12}>
            {banners.logos && banners?.logos.length > 0
              ? renderCarousel()
              : renderCarouselWithDefaultBanner()}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
