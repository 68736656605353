import Comments1 from "./assets/Comments1.png"
import Comments2 from "./assets/Comments2.png"
import Comments3 from "./assets/Comments3.png"
import Comments4 from "./assets/Comments4.png"
import Comments5 from "./assets/Comments5.png"
import Comments6 from "./assets/Comments6.png"

export const CommentsImages = [
  {
    image: Comments1,
  },
  {
    image: Comments2,
  },
  {
    image: Comments3,
  },
  {
    image: Comments4,
  },
  {
    image: Comments5,
  },
  {
    image: Comments6,
  },
]
