import { styled, Typography, css, Box } from "@mui/material"
import { HealthAndWellbeing } from "../../../../graphqGenaretedTypes"
import Content from "./Content"
import exclamationMark from "../assets/exclaimation-mark.png"
import yourCareLogo from "../assets/your-care.png"
import { Button } from "./Button"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"

const DEFAULT_TEL = "03303 800658*"
const DEFAULT_FOOTER_TEL = "0800 023 9324"

export function GreenBoxContent({ data }: { data: HealthAndWellbeing }) {
  const { eapNumber, level } = data
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  if (level === "hidden") {
    return (
      <ExclamationMark
        src={exclamationMark}
        alt={t("healthAndWellbeing.images.exclamation")}
      />
    )
  }

  if (level === "disabled") {
    return (
      <>
        <ExclamationMark
          src={exclamationMark}
          alt={t("healthAndWellbeing.images.exclamation")}
          sx={isMobileView ? { height: "132px", marginBottom: "25px" } : null}
        />
        {isMobileView && <Button data={data} />}
      </>
    )
  }
  if (level === "level_1" || level === "level_2") {
    return (
      <>
        <Box sx={isMobileView ? { marginBottom: "34px" } : undefined}>
          <ContentHeader variant="h4">
            {t("healthAndWellbeing.contentHeader")}
          </ContentHeader>
          {eapNumber ? (
            <CustomNumber eapNumber={eapNumber} isYourCare={false} />
          ) : (
            <DefaultNumber isYourCare={false} />
          )}
        </Box>
        {isMobileView && <Button data={data} />}
      </>
    )
  }
  // level_3 & level_4 & level_5
  return (
    <>
      <YourCareLogo
        src={yourCareLogo}
        alt={t("healthAndWellbeing.images.yourCare")}
        sx={
          level === "level_5"
            ? isMobileView
              ? { height: "87px", marginBottom: "50px" }
              : { height: "120px" }
            : isMobileView
            ? { height: "57px" }
            : { height: "86px" }
        }
      />
      {level !== "level_5" && (
        <Box sx={isMobileView ? { marginBottom: "17px" } : undefined}>
          <YourCareContentHeader variant="h4">
            {t("healthAndWellbeing.contentHeader")}
          </YourCareContentHeader>
          {eapNumber ? (
            <CustomNumber eapNumber={eapNumber} isYourCare />
          ) : (
            <DefaultNumber isYourCare />
          )}
        </Box>
      )}
      {isMobileView && <Button data={data} />}
    </>
  )
}

const YourCareLogo = styled("img")`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 16px;
    }
  `}
`

const ExclamationMark = styled("img")`
  display: block;
  margin: 0 auto;
`

function DefaultNumber({ isYourCare }: { isYourCare: boolean }) {
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }

  if (isYourCare) {
    return (
      <>
        <YourCareTel>{DEFAULT_TEL}</YourCareTel>
        <YourCareFooter>
          {t("healthAndWellbeing.defaultFooter", { DEFAULT_FOOTER_TEL })}
        </YourCareFooter>
      </>
    )
  }
  return (
    <>
      <Tel>{DEFAULT_TEL}</Tel>
      <Footer>
        {t("healthAndWellbeing.defaultFooter", { DEFAULT_FOOTER_TEL })}
      </Footer>
    </>
  )
}

function CustomNumber({
  eapNumber,
  isYourCare,
}: {
  eapNumber: string | undefined
  isYourCare: boolean
}) {
  if (isYourCare) {
    return (
      <>
        <YourCareTel>{eapNumber}</YourCareTel>
        {/* empty Footer for grid */}
        <Footer></Footer>
      </>
    )
  }
  return (
    <>
      <Tel>{eapNumber}</Tel>
      {/* empty Footer for grid */}
      <Footer></Footer>
    </>
  )
}
export default Content
const ContentHeader = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: 19px;
    line-height: 22px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 24px;
      line-height: 30px;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 27px;
      line-height: 31px;
    }
  `}
`
const YourCareContentHeader = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: 15px;
    line-height: 18px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 24px;
      line-height: 30px;
    }
  `}
`

const Tel = styled(Typography)`
  ${({ theme }) => css`
    font-size: 44px;
    line-height: 51px;
    font-weight: bold;
    color: #488200;
    margin: 8px auto;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 50px;
      line-height: 60px;
      margin: 12px auto;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 62px;
      line-height: 73px;
    }
  `}
`

const YourCareTel = styled(Typography)`
  ${({ theme }) => css`
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    color: #488200;
    margin: 4px auto;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 46px;
      line-height: 54px;
    }
  `}
`
const Footer = styled(Typography)`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 13px;
    color: ${theme.palette.primary.main};
    width: 60%;
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 12px;
      line-height: 14px;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      width: 46%;
      font-size: 14px;
      line-height: 18px;
    }
  `}
`
const YourCareFooter = styled(Typography)`
  ${({ theme }) => css`
    font-size: 6px;
    line-height: 7px;
    color: ${theme.palette.primary.main};
    width: 34%;
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 10px;
      line-height: 11px;
    }
  `}
`
