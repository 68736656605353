import React, { useState } from "react"
import classNames from "classnames"
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { useNavigate } from "react-router"
import { DepartmentType } from "../../layout/formatMegaMenuData"
import HealthAndWellbeingColumn from "./HealthAndWellbeingColumn"
import DesktopCard from "./HealthAndWellbeingDesktopCard"
import { Link } from "@mui/material"
import { gtmPortalHomepageHealthAndWellbeingLinkClick } from "../gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

export type MegaMenuHealthAndWellbeingProps = {
  name?: string
  level?: string | null
  link?: string | null
  categories: DepartmentType[]
  imageUrl?: string | null
  active?: boolean
  setActive: (tabActive: boolean) => void
  eapNumber?: string | null
}

function MegaMenuHealthAndWellbeingTab({
  link,
  categories,
  active = false,
  setActive,
  eapNumber,
  level,
}: MegaMenuHealthAndWellbeingProps) {
  const { t, ready } = useTranslation("layout")
  const numberOfCategories = Object.keys(categories).length
  const [, setActiveCategories] = useState(
    Array(numberOfCategories).fill(false)
  )
  const navigate = useNavigate()

  const handleLink = (url: string, external: boolean) => {
    if (link) {
      if (external) {
        window.open(url, "_blank")
      } else {
        navigate(link)
      }
      if (setActive) {
        setActive(false)
      }
    }
  }

  const nrOfFirstColumnCategories = ["level_1", "level_2", "level_3"].includes(
    level || ""
  )
    ? Math.floor(categories.length / 2)
    : Math.ceil(categories.length / 2)
  const clickLocation = "Header Mega Menu"

  if (!ready) {
    return null
  }
  return (
    <>
      <div
        className={classNames("mega-menu-tab", { "d-none": !active })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => {
          setActive(false)
          setActiveCategories(Array(numberOfCategories).fill(false))
        }}
      >
        <div className="mega-menu-tab__categories">
          {!(level === "disabled" || level === "hidden") ? (
            <Link
              component={"a"}
              className="mega-menu-tab__category mega-menu-tab__title"
              onMouseEnter={() =>
                setActiveCategories(Array(numberOfCategories).fill(false))
              }
              href={link || "#"}
              onClick={(event) => {
                setActive(false)
                const labelText = event.currentTarget.textContent
                gtmPortalHomepageHealthAndWellbeingLinkClick(
                  labelText,
                  link,
                  clickLocation
                )
              }}
            >
              {level === "level_1" && t("menu.exploreHealthAndWellbeing")}
              {(level === "level_3" || level === "level_4") &&
                t("menu.yourCareWellbeingPlatform")}
              {level === "level_2" && t("menu.exploreHealthAndWellbeing")}
              <ArrowForwardIcon
                fontSize="inherit"
                className="mega-menu-tab__forward-arrow"
                color="inherit"
              />
            </Link>
          ) : null}
          <div>
            <HealthAndWellbeingColumn
              setActive={setActive}
              items={categories.slice(0, nrOfFirstColumnCategories)}
            />
          </div>
        </div>
        <div className="mega-menu-tab__categories">
          <HealthAndWellbeingColumn
            setActive={setActive}
            items={categories.slice(
              nrOfFirstColumnCategories,
              categories.length
            )}
          />
        </div>

        <DesktopCard
          level={level}
          eapNumber={eapNumber}
          handleLink={handleLink}
          link={link}
        />
      </div>

      <div
        className={classNames("mega-menu-tab__background", {
          "d-none": !active,
        })}
      />
    </>
  )
}

export default MegaMenuHealthAndWellbeingTab
