import Footer from "../footer/Footer"
import Header from "../header/Header"
import {
  LSStoreLayoutContext,
  useLsStoreLayoutData,
} from "./LSStoreLayoutContext"
import {
  StoreCategoryContext,
  useCategoryLayoutData,
} from "./StoreCategoryContext"
import LifestyleMegaMenu from "../../shared/lifestyle-mega-menu/LifestyleMegaMenu"
import { Outlet, useParams } from "react-router"
import { StepMenuProps } from "../../shared/step-menu/StepMenu"
import BurgerMenu from "../../shared/nav/BurgerMenu"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export type globalHeaderData = {
  loading?: boolean
}

export const LSStoreLayout = function ({
  setShouldShowCookiePopup,
}: {
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const contextValue = useLsStoreLayoutData()
  const contextCategoryValue = useCategoryLayoutData()
  const params = useParams()
  const isLocalBenefits = window.location.pathname.includes("/local_benefits")

  const searchPath = isLocalBenefits
    ? `/organisations/${params.organisationId}/employee/lifestyle_savings/local_benefits`
    : `/organisations/${params.organisationId}/employee/lifestyle_savings/products`

  const {
    currentUserId,
    logo,
    condensedLogo,
    privacyNoticeUrl,
    supportLinks,
    organisationName,
    availableLanguages,
    defaultLanguage,
  } = contextValue
  const { categories, searchSuggestions, categoryHierarchy } =
    contextCategoryValue

  const { t, ready } = useTranslation("layout")

  const stepMenuProps: StepMenuProps = {
    title: t("menu.yourBenefits"),
    itemHierarchy: categoryHierarchy,
    levels: 2,
  }

  // LSS searchbox widget state
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)

  useEffect(() => {
    const url = location.href
    let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
    if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
      subdomain = "Vivup"
    }

    const title = `${subdomain}: Lifestyle Savings`
    if (title !== document.title) {
      document.title = title
    }
  }, [])

  if (!ready) {
    return null
  }
  return (
    <StoreCategoryContext.Provider value={contextCategoryValue}>
      <LSStoreLayoutContext.Provider value={contextValue}>
        {currentUserId && (
          <>
            <Header
              logo={logo}
              condensedLogo={condensedLogo}
              organisationName={organisationName}
              currentUserId={currentUserId}
              showBasket={false}
              showNoticeboard={[]}
              backendUser={false}
              isOrganisationSettingUser={false}
              MegaMenu={<LifestyleMegaMenu categories={categories} />}
              BurgerMenu={
                <BurgerMenu
                  stepMenuProps={stepMenuProps}
                  supportLinks={supportLinks}
                />
              }
              lsSearchBoxProps={{
                searchPath,
                suggestions: searchSuggestions,
                gtmEventType: "lifestyleSavings",
              }}
              isWidgetOpen={isWidgetOpen}
              setIsWidgetOpen={setIsWidgetOpen}
              availableLanguages={availableLanguages}
              defaultLanguage={defaultLanguage}
            />
            <div id="main-content" className="store-main-container">
              <Outlet />
            </div>
            <Footer
              privacyNoticeUrl={privacyNoticeUrl}
              organisationPrivacyNotice={contextValue.organisationPrivacyNotice}
              setShouldShowCookiePopup={setShouldShowCookiePopup}
            />
          </>
        )}
      </LSStoreLayoutContext.Provider>
    </StoreCategoryContext.Provider>
  )
}
