import Header from "../layout/header/Header"
import Footer from "../layout/footer/Footer"
import {
  RegistrationLayoutContext,
  useRegistrationLayoutData,
} from "./RegistrationLayoutContext"
import { Outlet } from "react-router"
import { useEffect } from "react"
import extractSubdomain from "../../utils/extractSubdomain"
import Loader from "../shared/Loader"

export const RegistrationLayout = function ({
  setShouldShowCookiePopup,
}: {
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { data } = useRegistrationLayoutData()
  const PageName = () => {
    const url = location.href
    let sanitisedSchemeType
    if (url.includes("sign_up")) {
      sanitisedSchemeType = "Registration"
    } else if (url.includes("sign_in")) {
      sanitisedSchemeType = "Sign In"
    } else if (url.includes("invitation/accept")) {
      sanitisedSchemeType = "Registration Confirmation"
    } else if (
      url.includes("validation_numbers/new") ||
      url.includes("request_review") ||
      url.includes("confirmation_required")
    ) {
      sanitisedSchemeType = "Registration validation"
    } else if (url.includes("approval_required")) {
      sanitisedSchemeType = "Thank you"
    } else if (url.includes("password/edit")) {
      sanitisedSchemeType = "Change your password"
    } else if (url.includes("password_expired")) {
      sanitisedSchemeType = "Renew your password"
    } else if (url.includes("*")) {
      sanitisedSchemeType = "Not found"
    } else if (url.includes("submit_my_data_request")) {
      sanitisedSchemeType = "Delete Account Request Process"
    }
    return sanitisedSchemeType
  }

  useEffect(() => {
    const currentUrl = location.href
    const subdomain = extractSubdomain(currentUrl)

    document.title = `${subdomain}: ${PageName()}`
  }, [])

  return (
    <RegistrationLayoutContext.Provider value={data}>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          <Header showBasket={false} showNoticeboard={[]} enableLink={false} />
          <div className="static-main-container">
            <Outlet />
          </div>
          <Footer setShouldShowCookiePopup={setShouldShowCookiePopup} />
        </>
      )}
    </RegistrationLayoutContext.Provider>
  )
}
