import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  css,
} from "@mui/material"
import RightArrow from "./assets/RightArrow.png"
import Click from "./assets/Click.png"
import Heart from "./assets/Heart.png"
import Calender from "./assets/Calender.png"
import Person from "./assets/Person.png"
import { useTranslation } from "react-i18next"
import { WavePayRollPage } from "../../shared/wave-svg/WavePayRollPage"
import { ButtonStyle } from "../cycle-to-work/BikeShopHardCodeComponent"
import { PayRollGivingProps } from "./StartExploringPayRollGiving"

const FourSimpleSteps: React.FC<PayRollGivingProps> = ({ link }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { t, ready } = useTranslation("payrollGiving")
  if (!ready) {
    return null
  }

  return (
    <WaveContainer>
      <WaveBackgroundContainer>
        <WavePayRollPage />
        <MainContainer>
          <SectionText sx={{ textAlign: "center" }}>
            {t("payrollGiving.fourSimpleSteps.header")}
          </SectionText>
          <CardLayout>
            <CardBox sx={{ height: isDesktop ? "" : "10.75rem !important" }}>
              <div>
                <ImageContentContainer sx={{ paddingTop: "0rem !important" }}>
                  <ImageContainer>
                    <CalenderImage
                      src={Calender}
                      alt={"Monthly calendar icon"}
                    />
                  </ImageContainer>
                </ImageContentContainer>

                <TextContainer>
                  {t("payrollGiving.fourSimpleSteps.firstStep")}
                </TextContainer>
              </div>
            </CardBox>
            <ImageWrap>
              <div>
                <ArrowImage
                  src={RightArrow}
                  alt={t("payrollGiving.fourSimpleSteps.rightArrow")}
                />
              </div>
            </ImageWrap>
            <CardBox>
              <div>
                <ImageContentContainer>
                  <ImageContainer>
                    <ClickImage src={Click} alt={"Clickable link icon"} />
                  </ImageContainer>
                </ImageContentContainer>

                <TextContainer>
                  {t("payrollGiving.fourSimpleSteps.secondStep")}
                </TextContainer>
              </div>
            </CardBox>
            <ImageWrap>
              <div>
                <ArrowImage
                  src={RightArrow}
                  alt={t("payrollGiving.fourSimpleSteps.rightArrow")}
                />
              </div>
            </ImageWrap>
            <CardBox>
              <div style={{ marginTop: isDesktop ? "" : "5rem" }}>
                <ImageContentContainer>
                  <ImageContainer>
                    <PersonImage src={Person} alt={"User profile icon"} />
                  </ImageContainer>
                </ImageContentContainer>

                <TextContainer>
                  {t("payrollGiving.fourSimpleSteps.thirdStep")}
                </TextContainer>
              </div>
            </CardBox>
            <ImageWrap>
              <div>
                <ArrowImage
                  src={RightArrow}
                  alt={t("payrollGiving.fourSimpleSteps.rightArrow")}
                />
              </div>
            </ImageWrap>
            <CardBox>
              <div style={{ marginTop: isDesktop ? "" : "9rem" }}>
                <ImageContentContainer>
                  <ImageContainer>
                    <HeartImage src={Heart} alt="Heart icon" />
                  </ImageContainer>
                </ImageContentContainer>
                <TextContainer>
                  {t("payrollGiving.fourSimpleSteps.fourthStep")}
                  <p style={{ fontSize: "12px", marginTop: "0rem" }}>
                    {t("payrollGiving.fourSimpleSteps.fourthStepInfo")}
                  </p>
                </TextContainer>
              </div>
            </CardBox>
          </CardLayout>
          <ExplorePayrollGivingButton
            href={link}
            component={"a"}
            aria-label="Explore payroll giving button"
            rel="noopener"
          >
            {t("payrollGiving.fourSimpleSteps.explorePayrollButton")}
          </ExplorePayrollGivingButton>
        </MainContainer>
      </WaveBackgroundContainer>
    </WaveContainer>
  )
}
export default FourSimpleSteps
export const ExplorePayrollGivingButton = styled(ButtonStyle)`
  ${({ theme }) => css`
    && {
      text-align: center;
      margin-top: 3rem;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        margin-top: 9.5rem;
      }
    }
  `}
`
export const WaveBackgroundContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    margin-top: -21rem;
    padding-top: 8.5rem;
    z-index: 5;
    display: flex;
    justify-content: center;
    justify-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 3rem;
      padding-top: 3rem;
    }
  `}
`
export const WaveContainer = styled("div")`
  ${({ theme }) => css`
    margin-top: 19rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 2rem;
    }
  `}
`
export const ArrowImage = styled("img")`
  width: 1.5rem;
  height: 2.4375rem;
`

export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 36.875rem;
    background-color: ${theme.palette.grey[200]};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 69.625rem;
      justify-content: flex-start;
    }
  `}
`
export const CardBox = styled(Box)`
  ${({ theme }) => css`
    height: 18.3125rem;
    max-width: 15.625rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 20.875rem;
      width: 100%;
      height: 12.5rem;
    }
  `}
`
export const CardLayout = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      max-width: 20.875rem;
      width: 100%;
      padding: 0rem;
    }
  `}
`

export const TextContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 15.625rem;
    width: 100%;
    height: 8.6875rem;
    padding-top: 2rem;
    text-align: center;
    color: ${theme.palette.primary.main};
    font-style: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    font-family: Raleway;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 20.875rem;
      font-size: 0.875rem;
      width: 100%;
      height: 4rem;
      padding-top: 1.125rem;
    }
  `}
`
export const ImageWrap = styled(Box)`
  ${({ theme }) => css`
    height: 12.5rem;
    display: block;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      display: none;
    }
  `}
`

export const ImageContentContainer = styled("div")`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 2.5rem;
    }
  `}
`
export const SectionText = styled(Typography)`
  ${({ theme }) => css`
    max-width: 41.0625rem;
    width: 100%;
    height: 3.625rem;
    margin-bottom: 1.875rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    font-family: Raleway;
    color: ${theme.palette.primary.main};
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 17.0625rem;
      width: 100%;
      height: 3.8125rem;
      font-size: 1rem;
      margin-bottom: 0.8125rem;
      text-align: center;
      padding-top: 2rem;
      line-height: 1.25rem;
    }
  `}
`
export const PayrollGivingButton = styled(Typography)`
  ${({ theme }) => css`
    max-width: 41.0625rem;
    width: 100%;
    height: 3.625rem;
    margin-bottom: 1.875rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    font-family: Raleway;
    color: ${theme.palette.primary.main};
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 17.0625rem;
      width: 100%;
      height: 3.8125rem;
      font-size: 1rem;
      margin-bottom: 0.8125rem;
      text-align: center;
      padding-top: 2rem;
      line-height: 1.25rem;
    }
  `}
`
const CalenderImage = styled("img")`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    max-width: 4.8125rem;
    max-height: 3.9375rem;
    object-fit: contain;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 3.1625rem;
      max-height: 2.1081rem;
    }
  `}
`
const ClickImage = styled("img")`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    max-width: 4.3125rem;
    max-height: 3.75rem;
    object-fit: contain;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 2.7731rem;
      max-height: 2.3844rem;
    }
  `}
`
const PersonImage = styled("img")`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    max-width: 3.9375rem;
    max-height: 3.9375rem;
    object-fit: contain;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 2.5rem;
      max-height: 2.5rem;
    }
  `}
`
const HeartImage = styled("img")`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    max-width: 3.1875rem;
    max-height: 3.9375rem;
    object-fit: contain;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 2.0625rem;
      max-height: 2.5rem;
    }
  `}
`
export const ImageContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 7.625rem;
    height: 7rem;
    width: 100%;
    background-color: white;
    opacity: 1;
    border-radius: 3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 4.875rem;
      height: 5rem;
    }
  `}
`
