import React from "react"
import CalculateSavingsModal from "./CalculateSavingsModal"
import { styled, css } from "@mui/material"
import BannerCarousel from "../../shared/banner-carousel/BannerCarousel"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
const CalculatorBanner = () => {
  const [openModal, setOpenModal] = React.useState(false)
  const { i18n } = useTranslation()
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <ButtonContainer onClick={handleOpenModal}>
        <BannerCarousel
          desktopImageUrl={useBuildAwsImageUrl(
            i18n.language,
            "bike_shop_desktop_image.png"
          )}
          mobileImageUrl={useBuildAwsImageUrl(
            i18n.language,
            "bike_shop_mobile_image.png"
          )}
          showWelcomeText={false}
          link={null}
        />
        <div style={{ display: "none" }}>Calculate your savings</div>
      </ButtonContainer>
      <CalculateSavingsModal
        openModal={openModal}
        handleClose={handleCloseModal}
      />
    </>
  )
}

export default CalculatorBanner

const ButtonContainer = styled("button")`
  ${({ theme }) => css`
    max-width: 1082px;
    width: 100%;
    height: 82px;
    margin-bottom: 1rem;
    padding: 0rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
      opacity: 75%;
    }
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
      height: 81px;
      margin-top: 20px;
      margin-bottom: 16px;
    }

    /* styling for BannerCarousel child */
    & > div {
      border-radius: 1rem;
    }
  `}
`
