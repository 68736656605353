import insurance from "./../assets/insurance.png"
import servicing from "./../assets/servicing.png"
import replacementTyres from "./../assets/replacement-tyres.png"
import mot from "./../assets/mot.png"
import roadTax from "./../assets/road-tax.png"
import breakdownCover from "./../assets/breakdown-cover.png"
import lifestyleProtections from "./../assets/lifestyle-protection.png"

const hardCodedData = [
  { imageUrl: insurance, label: "insurance" },
  { imageUrl: servicing, label: "servicing" },
  {
    imageUrl: replacementTyres,

    label: "replacementTyres",
  },
  { imageUrl: mot, label: "mot" },
  { imageUrl: roadTax, label: "roadTax" },
  {
    imageUrl: breakdownCover,

    label: "breakdownCover",
  },
  {
    imageUrl: lifestyleProtections,

    label: "lifestyleProtections",
  },
]

export default hardCodedData
