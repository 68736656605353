import { Box, css, styled } from "@mui/material"
import { GreenBoxContent } from "./GreenBoxContent"
import { Header } from "./Header"
import { colourBox } from "../../shared/SharedStyles"
import { HealthAndWellbeing } from "../../../../graphqGenaretedTypes"
import useIsMobileView from "../../../../utils/useIsMobileView"
import ExpandableList from "../ExpandableList"

function Content({ data }: { data: HealthAndWellbeing }) {
  const { level, usefulLinks } = data
  const isMobileView = useIsMobileView()

  return (
    <>
      <Header data={data} />
      {level === "level_1" || level === "level_2" ? (
        <PaddingOuterGrid>
          <PaddingContentGrid>
            <GreenBoxContent data={data} />
          </PaddingContentGrid>
        </PaddingOuterGrid>
      ) : (
        <OuterGrid>
          <ContentGrid>
            <GreenBoxContent data={data} />
          </ContentGrid>
        </OuterGrid>
      )}
      {isMobileView && usefulLinks && (
        <ExpandableList
          links={usefulLinks}
          moreButtonColor="#488200"
          isExternalLink
        />
      )}
    </>
  )
}

export default Content

const PaddingOuterGrid = styled(colourBox)`
  ${({ theme }) => css`
    background-color: #ecf2e5;
    display: grid;
    place-items: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      place-items: unset;
    }
  `}
`
const PaddingContentGrid = styled(Box)`
  ${({ theme }) => css`
    display: grid;
    grid-auto-rows: min-content;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-top: 54px; // to align with Heading on the left box
    }
  `}
`

const OuterGrid = styled(colourBox)`
  background-color: #ecf2e5;
  display: grid;
  place-items: center;
`
export const ContentGrid = styled(Box)`
  display: grid;
  grid-auto-rows: min-content;
  width: 100%;
`
