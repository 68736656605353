import { createContext } from "react"
import { useParams } from "react-router"
import { ProductType } from "./product-card/ProductCard"
import { useSearchParams } from "react-router-dom"
import {
  GetStoreDataQueryHookResult,
  useGetStoreDataQuery,
  BrochureGroup,
} from "../../graphqGenaretedTypes"
import { BreadcrumbsType } from "../shared/CustomBreadcrumbs"
import * as _ from "lodash"
import { Department } from "./filters/department-hierarchy-filter/DepartmentHierarchyFilter"
import { useTranslation } from "react-i18next"

export type StoreFilters = {
  brands: ReadonlyArray<string>
  promotions: ReadonlyArray<string>
  departments: Department[]
  brochureGroups: BrochureGroup[]
}

export type StoreContextData = {
  filters: StoreFilters
  products: ReadonlyArray<ProductType>
  totalPages: number
  totalEntries: number
  breadcrumbs: Array<BreadcrumbsType>
  algoliaQueryId?: string
  quote_limit?: number
  benefit_name: string
  loading: boolean
}

const defaultValue: StoreContextData = {
  filters: {
    brands: [],
    promotions: [],
    departments: [],
    brochureGroups: [],
  },
  products: [],
  totalPages: 0,
  totalEntries: 0,
  breadcrumbs: [],
  benefit_name: "",
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const queryDataToProducts: (
  queryData: GetStoreDataQueryHookResult
) => ProductType[] = function (queryData) {
  let result: ProductType[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.store?.products) {
    result = data?.employeeOrganisation?.scheme?.store?.products.map(
      (product) => {
        return {
          id: product.productId,
          brochureGroupId: product.brochureGroupId,
          title: product.name,
          image: product.image,
          lowertag: true,
          price: product.price.toString(),
          totalAmount: product.totalAmount.toString(),
          altnativeImgText: "",
          flashdealtag: false,
          link: product.url,
          productTagName: product.productTagName || undefined,
          productTagImage: product.productTagImage || undefined,
          term: product.term,
          brand: product.brand,
          category: product.category,
          variant: product.variant,
          availableSizes: product.availableSizes || "[]",
          sku: product.sku || "",
          storeName: data?.employeeOrganisation?.scheme?.storeName || "",
          department: product.department || "",
          supplierName: product.supplierName || "",
        }
      }
    )
  }

  return result
}

const queryDataToFilters: (
  queryData: GetStoreDataQueryHookResult
) => StoreFilters = function (queryData) {
  const data = queryData.data
  const departments = data?.employeeOrganisation?.scheme?.store?.departments

  return {
    brands: data?.employeeOrganisation?.scheme?.store?.filters?.brands || [],
    promotions:
      data?.employeeOrganisation?.scheme?.store?.filters?.promotions?.map(
        (promotion) => _.startCase(promotion)
      ) || [],
    departments: departments || [],
    brochureGroups: data?.employeeOrganisation?.scheme?.brochureGroups,
  }
}

const queryDataToTotalPages: (
  queryData: GetStoreDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.store?.totalPages || 0
}

const queryDataToTotalEntries: (
  queryData: GetStoreDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.store?.totalEntries || 0
}

const queryDataToBreadcrumbs: (
  queryData: GetStoreDataQueryHookResult
) => BreadcrumbsType[] = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.store?.breadcrumbs || []
}

export const StoreContext = createContext(defaultValue)

export const useStoreData: () => {
  data: StoreContextData
} = function () {
  const { organisationId, schemeType } = useParams()
  const { i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const filters = {
    brands: searchParams.getAll("brands"),
    promo: searchParams
      .getAll("promo")
      .map((promo) => promo.replace(/\s+/g, "_").toLowerCase()),
    departments: searchParams.getAll("departments"),
    categories: searchParams.getAll("categories"),
    subcategories: searchParams.getAll("subcategories"),
    minPrice: parseFloat(searchParams.get("min")?.substr(1) || ""),
    maxPrice: parseFloat(searchParams.get("max")?.substr(1) || ""),
    search: searchParams.get("search"),
    bg: parseInt(searchParams.get("bg") || ""),
  }

  const data = useGetStoreDataQuery({
    variables: {
      organisationId: organisationId || "",
      schemeType: schemeType || "",
      filters: filters,
      page: parseInt(searchParams.get("page") || "1"),
      productsPerPage: parseInt(searchParams.get("results_per_page") || "0"),
      orderBy: searchParams.get("sort_by") || "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  if (!data.loading) {
    const products = queryDataToProducts(data)
    const totalPages = queryDataToTotalPages(data)
    const totalEntries = queryDataToTotalEntries(data)
    const filters = queryDataToFilters(data)
    const breadcrumbs = queryDataToBreadcrumbs(data)
    const loading = data.loading
    const benefit_name = data.data?.employeeOrganisation?.scheme?.benefitName
    const quote_limit = data.data?.employeeOrganisation?.scheme?.limit
    const algoliaQueryId =
      data.data?.employeeOrganisation?.scheme?.store?.algoliaQueryId

    state = {
      ...defaultValue,
      products,
      totalPages,
      totalEntries,
      filters,
      breadcrumbs,
      algoliaQueryId,
      benefit_name,
      quote_limit,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
