import { FamilyCare as FamilyCareType } from "../../../../graphqGenaretedTypes"
import useIsMobileView from "../../../../utils/useIsMobileView"
import ExpandableList from "../ExpandableList"
import { Header } from "./Header"
import familyCareLogo from "./assets/family-care-logo.png"
import { Button } from "./Button"
import childCareLogo from "./assets/child-care.png"
import elderCareLogo from "./assets/eldercare-support.png"
import petCareLogo from "./assets/petcare-support.png"
import i18n from "../../../../i18n"
import {
  PaddingOuterGrid,
  PaddingContentGrid,
  FamilyCareLogo,
  FamilyCareLogos,
  SupportType,
  Icon,
  IconText,
  MORE_BUTTON_COLOR,
} from "../../shared/family-care-and-family-pay/ContentStyles"

const getLogosData = () => [
  {
    label: i18n.t("portalHomepage:familyCare.services.childcare"),
    img: childCareLogo,
  },
  {
    label: i18n.t("portalHomepage:familyCare.services.eldercare"),
    img: elderCareLogo,
  },
  {
    label: i18n.t("portalHomepage:familyCare.services.petcare"),
    img: petCareLogo,
  },
]

export function Content({ data }: { data: FamilyCareType | undefined }) {
  const isMobile = useIsMobileView()
  const usefulLinks = data?.usefulLinks

  return (
    <>
      <Header />
      <PaddingOuterGrid>
        <PaddingContentGrid>
          <FamilyCareLogo src={familyCareLogo} alt="family care logo" />
          <FamilyCareLogos>
            {usefulLinks &&
              usefulLinks.map(({ label }) => {
                const logosData = getLogosData()
                const logo = logosData.find((data) => data.label == label)
                if (logo) {
                  return (
                    <SupportType key={`family-care-${label}`}>
                      <Icon src={logo.img} alt={label} />
                      <IconText>{label}</IconText>
                    </SupportType>
                  )
                }
              })}
          </FamilyCareLogos>
          {isMobile && <Button />}
        </PaddingContentGrid>
      </PaddingOuterGrid>
      {isMobile && usefulLinks && (
        <ExpandableList
          links={usefulLinks}
          moreButtonColor={MORE_BUTTON_COLOR}
        />
      )}
    </>
  )
}
