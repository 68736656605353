import { Close } from "@mui/icons-material"
import { Box, Button, Modal as MuiModal, css, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function Modal({
  open,
  handleClose,
  children,
}: {
  open: boolean
  handleClose: () => void
  children: JSX.Element
}) {
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }

  return (
    <MuiModal open={open} onClose={() => handleClose()}>
      <MainBox
        sx={{
          width: {
            xs: "80%",
            md: "32rem",
          },
        }}
      >
        <Button
          onClick={() => handleClose()}
          sx={{ alignSelf: "flex-end" }}
          title="Close"
        >
          <Close />
        </Button>
        {children}
        <CloseWindowButton variant="invisible" onClick={() => handleClose()}>
          {t("exploreYourBenefit.buttons.closeWindow")}
        </CloseWindowButton>
      </MainBox>
    </MuiModal>
  )
}

export const MainBox = styled(Box)(
  ({ theme }) => css`
    & {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 85vh;
      overflow: auto;
      background: ${theme.palette.white.main};
      border-radius: 1rem;
      padding-top: 1.25rem;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      outline: none;
    }
  `
)

export const CloseWindowButton = styled(Button)(
  ({ theme }) => css`
    & {
      color: ${theme.palette.grey[700]};
      text-decoration: underline;
      align-self: center;
      padding-right: 0.75rem; // there's no padding-y attribute in ordinary css
      padding-left: 0.75rem;
      &:hover {
        text-decoration: underline;
      }
    }
  `
)
