import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material"
import { useFormikContext } from "formik"
import ClearIcon from "@mui/icons-material/Clear"
import { SignUpFormikValue } from "./formikUtils"
import SearchIcon from "@mui/icons-material/Search"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { useTranslation } from "react-i18next"
import { SignUpContext } from "./SignUpContext"

const id = "organisation"

type OrganisationFieldType = {
  setOrgId: Dispatch<SetStateAction<string | undefined>>
}

const OrganisationField = ({ setOrgId }: OrganisationFieldType) => {
  const { t, ready } = useTranslation("regForm")

  const { organisations: organisationsList } = useContext(SignUpContext)

  const [inputValue, setInputValue] = useState("")

  const formik = useFormikContext<SignUpFormikValue>()
  const touched = Object.keys(formik.touched).includes(id)
  const error = Object.keys(formik.errors).includes(id)
  const invalid = touched && error ? true : false

  const [inputFocus, setInputFocus] = useState(false)

  const handleFocus = () => {
    setInputFocus(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    setInputFocus(false)
    formik.handleBlur(e)
  }

  const handleClearIconClick = () => {
    setOrgId(undefined)
  }

  const shouldOpenPopper = inputValue.length > 1 ? true : false

  if (!ready) {
    return null
  }
  return (
    <div>
      <Autocomplete
        sx={{
          marginBottom: "1.875rem",
          "& .MuiAutocomplete-endAdornment": { top: "unset" },
        }}
        componentsProps={{
          popper: { open: shouldOpenPopper },
          // paper: { sx: { maxHeight: "15.8rem" } },
        }}
        disablePortal // for iOS voice over support
        fullWidth
        id={id}
        options={organisationsList}
        popupIcon={shouldOpenPopper && <ArrowDropDownIcon />}
        isOptionEqualToValue={(option, value) => {
          return option.label === String(value)
        }}
        filterOptions={(options, { getOptionLabel }) => {
          return options.filter((option) =>
            getOptionLabel(option)
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          )
        }}
        clearIcon={
          formik.values.organisation.label || inputValue ? (
            <IconButton
              size="small"
              aria-label="Clear input"
              disableRipple
              onClick={handleClearIconClick}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : null
        }
        inputValue={inputValue}
        value={formik.values[id].label || inputValue}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue)
        }}
        onChange={(e, value) => {
          if (!value) {
            formik.setFieldValue(id, formik.initialValues.organisation)
          } else {
            formik.setFieldValue(id, value)
            setOrgId(value.id)
          }
        }}
        onBlur={(e: React.FocusEvent<HTMLDivElement, Element>) => handleBlur(e)}
        onFocus={handleFocus}
        noOptionsText={t("organisation.noOptions")}
        ListboxProps={{ role: "listbox" }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required
              label={t("organisation.label")}
              aria-label={t("organisation.label")}
              error={invalid}
              helperText={invalid && formik.errors[id]?.label}
              InputLabelProps={{
                shrink:
                  Boolean(formik.values[id].label) ||
                  Boolean(inputValue) ||
                  inputFocus ||
                  false,
                style:
                  Boolean(formik.values[id].label) ||
                  Boolean(inputValue) ||
                  inputFocus
                    ? undefined
                    : { marginLeft: 30 },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledSearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          )
        }}
      />
    </div>
  )
  return <></>
}

export default OrganisationField

const StyledSearchIcon = styled(SearchIcon)`
  color: #666666;
  margin: 0 0.5rem;
`
