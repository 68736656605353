import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { CarouselContainer } from "../lifestyle-savings/homepage/CarouselContainer"
import CarouselSlider from "../shared/carousel-slider/CarouselSlider"
import { ProductType } from "../store/product-card/ProductCard"
import { useParams } from "react-router-dom"
import snakeToCamel from "../../utils/snakeToCamel"

const PopularBikes = ({
  popularBikes,
  isCycleToWork,
  isBikeShop,
}: {
  popularBikes: ProductType[]
  isCycleToWork?: boolean
  isBikeShop?: boolean
}) => {
  const theme = useTheme()
  const params = useParams()
  const isRecommendedProduct =
    isBikeShop || isCycleToWork ? "Recommended object clicked" : ""
  const { t, ready } = useTranslation(
    snakeToCamel(params.schemeType || "bike_shop")
  )

  if (!ready) {
    return null
  }

  return (
    <CarouselContainer
      title={t("landing.popularBikeTitle")}
      showArrow={false}
      Slider={
        <CarouselSlider
          linearGradient={theme.palette.grey[200]}
          slideData={popularBikes}
          store="cycle_to_work"
          isCycleToWork={isCycleToWork}
          isRecommendedProduct={isRecommendedProduct}
        />
      }
    />
  )
}

export default PopularBikes
