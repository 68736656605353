import React from "react"
import Slide from "./Slide"
import DrawerItem from "../drawer/DrawerItem"
import onEnter from "../../../utils/onEnter"
import {
  gtmLifestyleTopNavClick,
  gtmPortalHomepageHealthAndWellbeingLinkClick,
} from "../gtm-events/CustomGtmEvents"

export interface Child {
  name: string
  link: string
  external?: boolean
}

export interface Parent {
  name: string
  link?: string
  children: Child[]
}

export type GenerateSlideProps = {
  currentLevel: number
  name: string
  link?: string | null
  external?: boolean
  featured?: boolean
  level?: string | null
  previousLabel: string
  index: number
  children: {
    name: string
    link?: string
    external?: boolean
    children?: Child[] | Parent[]
    isBold?: boolean
    isExtraOption?: boolean
  }[]
  hideLevels: boolean[]
  setHideLevels: (hideLevel: boolean[]) => void
  setOpen?: (state: boolean) => void
  imgUrl?: string | null
  eapNumber?: string | null
  customLink?: string | null
}

function GenerateSlide({
  currentLevel,
  name,
  link,
  external,
  featured,
  previousLabel,
  children,
  hideLevels,
  setHideLevels,
  setOpen,
  imgUrl,
  level,
  eapNumber,
  customLink,
}: GenerateSlideProps) {
  const handleAction = (
    url: string,
    external: boolean,
    category: string,
    subCategory: string
  ) => {
    const navTypeSelected = url.split("/")?.pop()?.split("?")[0]

    if (["Nursery", "Childminder", "Holiday Clubs"].includes(subCategory)) {
      window.open(url, "_blank")
      return
    }

    if (url.includes("lifestyle_savings"))
      gtmLifestyleTopNavClick(navTypeSelected, category, subCategory)

    if (external === true || external === false) {
      window.location.href = url
    }
    if (setOpen) {
      setOpen(false)
    }
  }
  const clickLocation = "Header Mega Menu"
  const Label = children.map((item) => {
    return item.label
  })

  return (
    <Slide
      level={currentLevel}
      setOpen={setOpen}
      name={name}
      link={link}
      external={external}
      featured={featured}
      previousLabel={previousLabel}
      imgUrl={imgUrl}
      HealthAndWellBeingLevel={level}
      eapNumber={eapNumber}
      customLink={customLink}
      label={Label}
      children={children.map((child, index) => (
        <React.Fragment key={index}>
          {child.children && child.children.length > 0 ? (
            <GenerateSlide
              currentLevel={currentLevel + 1}
              name={child.name}
              link={child.link}
              external={child.external}
              previousLabel={name}
              children={child.children}
              hideLevels={hideLevels}
              setHideLevels={setHideLevels}
              setOpen={setOpen}
              index={index}
            />
          ) : (
            <DrawerItem
              text={child.name}
              divider
              onClick={() => {
                handleAction(
                  child.link || "",
                  name === "healthAndWellbeing"
                    ? true
                    : child.external || false,
                  name,
                  child?.name
                )

                name === "healthAndWellbeing" &&
                  gtmPortalHomepageHealthAndWellbeingLinkClick(
                    child.name,
                    child.link,
                    clickLocation
                  )
              }}
              onKeyDown={(event) =>
                onEnter(event, () =>
                  handleAction(
                    child.link || "",
                    child.external || false,
                    name,
                    child?.name
                  )
                )
              }
              isBold={child.isBold}
              isExtraOption={child.isExtraOption}
            />
          )}
        </React.Fragment>
      ))}
      setHideLevel={(hideLevel) =>
        setHideLevels(
          hideLevels.map((oldHideLevel, i) => {
            return currentLevel === i + 1 ? hideLevel : oldHideLevel
          })
        )
      }
    />
  )
}

export default GenerateSlide
