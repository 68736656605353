import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetLsLayoutDataQueryHookResult,
  useGetLsLayoutDataQuery,
} from "../../../graphqGenaretedTypes"
import { SupportLinksProps } from "../../shared/store/SupportLinks"
import { useTranslation } from "react-i18next"
import {
  AvailableLanguagesType,
  DefaultLanguageType,
  fallbackLanguage,
} from "../../../utils/languageUtils"

export type LSStoreLayoutContextData = {
  supportLinks: SupportLinksProps["linkList"]
  organisationName: string
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  currentUserId?: number
  logo?: string
  condensedLogo?: string
  loading: boolean
  departments?: string
  searchSuggestions?: string
  supplierDesktopBanner?: string
  supplierMobileBanner?: string
  availableLanguages: AvailableLanguagesType
  defaultLanguage: DefaultLanguageType
}

const defaultValue: LSStoreLayoutContextData = {
  organisationName: "Vivup",
  privacyNoticeUrl: undefined,
  organisationPrivacyNotice: false,
  supportLinks: [],
  loading: true,
  availableLanguages: [],
  defaultLanguage: fallbackLanguage,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const valueFromQueryResult = function (
  result: GetLsLayoutDataQueryHookResult,
  organisationId?: string
): LSStoreLayoutContextData {
  const data = result.data

  const loading = result.loading

  let privacyNoticeUrl = undefined
  if (organisationId) {
    privacyNoticeUrl =
      data?.employeeOrganisation?.customPrivacyNoticeUrl ??
      `/organisations/${organisationId}/privacy_notice`
  }

  if (!loading) {
    state = {
      ...defaultValue,
      privacyNoticeUrl: privacyNoticeUrl,
      organisationPrivacyNotice:
        data?.employeeOrganisation?.organisationPrivacyNotice ?? false,
      currentUserId: data?.currentUser?.id || undefined,
      logo: data?.employeeOrganisation?.logo || undefined,
      condensedLogo: data?.employeeOrganisation?.condensedLogo || undefined,
      supportLinks:
        data?.employeeOrganisation?.lifestyleSavings?.supportLinks || [],
      organisationName: data?.employeeOrganisation?.name || "Vivup",
      loading: loading,
      availableLanguages: data?.employeeOrganisation?.availableLanguages || [],
      defaultLanguage:
        data?.employeeOrganisation?.defaultLanguage || fallbackLanguage,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }
  return state
}

export const useLsStoreLayoutData = function (): LSStoreLayoutContextData {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetLsLayoutDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  return valueFromQueryResult(data, params.organisationId)
}

export const LSStoreLayoutContext = createContext(defaultValue)
