import { Box, styled } from "@mui/material"
import thankYouImg from "./assets/thank-you.jpg"
import C2WThankYouImg from "./assets/c2w-thank-you.png"

export const Background: React.FC<
  React.PropsWithChildren<{ storeName: string }>
> = ({ storeName, children }) => {
  if (storeName === "Home and Electronics") {
    return (
      <BackgroundImage className="globalheader-main-container">
        {children}
      </BackgroundImage>
    )
  }
  return (
    <C2WBackgroundImage className="globalheader-main-container">
      {children}
    </C2WBackgroundImage>
  )
}

const BackgroundImage = styled(Box)`
  display: flex;
  justify-content: center;
  background-image: url("${thankYouImg}");
  background-size: cover;
  width: 100%;
  height: 100%;
`

const C2WBackgroundImage = styled(BackgroundImage)`
  background-image: url("${C2WThankYouImg}");
`
