import { styled, css } from "@mui/material"
import ChildrenPlaying from "./assets/ChildrenPlaying.png"
import { WaveSvgCtw } from "../shared/wave-svg/WaveSvgCtw"
import {
  MainBox,
  WaveBackgroundContainer,
  ContentContainer,
  ImageGrid,
  MainGrid,
  ImageContainer,
  TextContainer,
  TextTitle,
  ParagraphText,
  ContentGrid,
  ButtonContainer,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/Eligibility"
import { ButtonStyle } from "./Howbenifitworks"
import { FamilyCareProps } from "./FamilyCare"
const Ofsted: React.FC<FamilyCareProps> = ({ link }) => {
  return (
    <MainBox>
      <WaveBackgroundContainer>
        <WaveSvgCtw />
        <ContentContainer>
          <MainGrid container>
            <ContentGrid item xs={12} md={5}>
              <TextContainer>
                <TextTitle>FAQs</TextTitle>
                <ParagraphText>
                  Find the answers to all your Family Pay
                  <br />
                  questions via our handy FAQ page
                </ParagraphText>

                <ButtonContainer>
                  <Button
                    variant="gradient"
                    href={link}
                    component={"a"}
                    target="_blank"
                  >
                    View Our FAQs
                  </Button>
                </ButtonContainer>
              </TextContainer>
            </ContentGrid>
            <ImageGrid item xs={12} md={7}>
              <ImageContainer src={ChildrenPlaying} alt="ChildrenPlaying" />
            </ImageGrid>
          </MainGrid>
        </ContentContainer>
      </WaveBackgroundContainer>
    </MainBox>
  )
}
export default Ofsted

export const Button = styled(ButtonStyle)`
  ${({ theme }) => css`
    && {
      color: ${theme.palette.white.main};
    }
  `}
`
