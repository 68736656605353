import { useContext, useState } from "react"
import { flushSync } from "react-dom"
import {
  FormContainer,
  StyledForm,
  SubmitButton,
} from "../shared/mainPageStyles"
import {
  regConfirmationInitialValues,
  RegConfirmationValue,
  regConfirmationValidationSchema,
  RegConfirmationFieldsWithValidation,
} from "../reg-confirm/formikUtils"
import {
  Formik,
  FormikHelpers,
  FormikProps,
  setNestedObjectValues,
} from "formik"
import { PasswordFields } from "../sign-up/PasswordFields"
import { SalaryBracketField } from "../sign-up/SalaryBracketField"
import TermAndConditionCheckBox from "../sign-up/TermAndConditionCheckBox"
import { Headers } from "../reg-confirm/Headers"
import { useTranslation } from "react-i18next"
import GenericField from "../../shared/form/GenericField"
import { getConfirmationPersonalEmailField } from "./groupRegFields"
import NonEditableEmailField from "./NonEditableEmailField"
import { RequiredText } from "../sign-up/ColleagueField"
import { styled } from "@mui/material"
import scrollToElement from "../../cycle-to-work/quote-page/scrollToElement"
import Alert from "../../shared/Alert"
import Layout from "../Layout"
import { RegConfirmContext, useRegConfirmData } from "./RegConfirmContext"
import { useAcceptInvitationMutation } from "../../../graphqGenaretedTypes"
import { useSearchParams } from "react-router-dom"
import { RegistrationLayoutContext } from "../RegistrationLayoutContext"
import LoggedOutBanner from "../LoggedOutBanner"
import MobileNumberField from "../MobileNumberField"
import { TcWarning } from "../sign-up/SignUp"
import { getShouldShowBanner } from "../registrationUtils"

const RegConfirm = () => {
  const [alertMessage, setAlertMessage] = useState("")
  const [errorCount, setErrorCount] = useState(0)
  const [, setValues] = useState(regConfirmationInitialValues)
  const { t, ready } = useTranslation(["regForm", "bikeQuote"])
  const color = "#666666"
  const { data } = useRegConfirmData()
  const [acceptInvitationMutation] = useAcceptInvitationMutation()
  const [searchParams] = useSearchParams()

  let invitationToken = searchParams.get("invitation_token")
  let tokenType = "invitation_token"

  if (!invitationToken) {
    invitationToken = searchParams.get("e")
    tokenType = "mapp_token"
  }
  const { showBanner: portalProviderShowBanner, banner } = useContext(
    RegistrationLayoutContext
  )
  const [showCheckboxError, setShowCheckBoxError] = useState(false)

  const globalShowBanner = banner?.show
  const shouldShowBanner = getShouldShowBanner(
    globalShowBanner,
    portalProviderShowBanner
  )

  const handleConfirmAccountClick = async (
    formik: FormikProps<RegConfirmationValue>
  ) => {
    formik.isValidating = true

    // if the TC box is checked?
    if (!formik.values.checkedTC) {
      setShowCheckBoxError(true)
      formik.isValidating = false
    } else {
      setShowCheckBoxError(false)
    }

    const validationErrors = await formik.validateForm() // exclude checkbox

    if (Object.keys(validationErrors).length > 0) {
      formik.setTouched(setNestedObjectValues(validationErrors, true))

      const errorFieldId = RegConfirmationFieldsWithValidation.find((field) =>
        Object.keys(validationErrors).find((errorField) => errorField == field)
      )

      scrollToElement(`#${errorFieldId}`)
      formik.isValidating = false
      return
    }

    // call default formik.handleSubmit(), then will call handleFormSubmit()
    formik.handleSubmit()
  }
  const handleFormSubmit = async (
    values: RegConfirmationValue,
    formikHelper: FormikHelpers<RegConfirmationValue>,
    prefilledEmail: string
  ) => {
    // force rendering, otherwise setValues() won't update the values right away (due to React 18 batching)
    flushSync(() => {
      setValues(values)
    })

    // if fill-in the email field, send both in mutation.
    // Otherwise send empty string representing none
    let email = ""
    let workEmail = ""

    if (values.confirmationPersonalEmail) {
      email = values.confirmationPersonalEmail
      workEmail = prefilledEmail
    }
    const salaryBracketId = values.salaryBracket || ""

    // mutation
    const { data } = await acceptInvitationMutation({
      variables: {
        email: email,
        mobileNumber: values.mobileNumber,
        invitationToken: invitationToken ?? "",
        tokenType: tokenType,
        password: values.password,
        passwordConfirmation: values.reEnterPassword,
        salaryBracketId: salaryBracketId,
        workEmail: workEmail,
      },
    })

    // error handling after mutation call
    const errors = data?.acceptInvitation?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setErrorCount(errorCount + 1)
      // setIsSubmitSuccess(false)
    } else {
      // setIsSubmitSuccess(true)

      const authToken = data?.acceptInvitation?.authToken

      if (authToken) {
        const queryString = new URLSearchParams({
          auth_token: authToken,
        }).toString()

        window.location.href = `/users/authorize_user?${queryString}`
      }
    }

    formikHelper.setSubmitting(false)
  }

  if (!ready) {
    return null
  }

  return (
    <RegConfirmContext.Provider value={data}>
      <Layout>
        <>
          <Formik
            initialValues={regConfirmationInitialValues}
            validationSchema={regConfirmationValidationSchema}
            onSubmit={(values, formikHelper) =>
              handleFormSubmit(values, formikHelper, data.email)
            }
          >
            {(formik) => {
              return (
                <StyledForm>
                  {alertMessage && (
                    <Alert
                      severity={"error"}
                      message={alertMessage}
                      key={errorCount}
                    />
                  )}
                  <FormContainer>
                    <Headers />
                    {!data.requiresDomainValidation && (
                      <GenericField
                        textFieldProps={getConfirmationPersonalEmailField()}
                      />
                    )}
                    <NonEditableEmailField label={data.email} />
                    <MobileNumberField
                      mobileNumberState={data.mobileNumberState}
                    />
                    <SalaryBracketField
                      salaryBrackets={data.salaryBrackets}
                      salaryBracketState={data.salaryBracketState}
                    />
                    <PasswordFields />
                    <StyledRequiredText sx={{ color: color }}>
                      * {t("regForm:regConfirm.requiredField")}
                    </StyledRequiredText>
                    <TermAndConditionCheckBox
                      showCheckboxError={showCheckboxError}
                    />
                    {showCheckboxError && (
                      <TcWarning>{t("bikeQuote:alertMessage")}</TcWarning>
                    )}
                    <SubmitButton
                      variant="gradient"
                      onClick={() => handleConfirmAccountClick(formik)}
                      disabled={formik.isSubmitting || formik.isValidating}
                    >
                      {t("regForm:regConfirm.confirmAccount")}
                    </SubmitButton>
                  </FormContainer>
                </StyledForm>
              )
            }}
          </Formik>
          {shouldShowBanner && banner?.heading && banner?.images && (
            <LoggedOutBanner
              bannerHeading={banner.heading}
              images={banner.images}
            />
          )}
        </>
      </Layout>
    </RegConfirmContext.Provider>
  )
}
export default RegConfirm

export const StyledRequiredText = styled(RequiredText)`
  &&& {
    margin-top: 0rem;
  }
`
