import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import Carousel from "react-material-ui-carousel"
import React, { useCallback, useEffect, useState } from "react"
import { useMediaQuery, useTheme } from "@mui/material"
import BannerCarousel, { BannerCarouselProps } from "./BannerCarousel"
import { useParams } from "react-router-dom"

export type BannerCarouselGridProps = {
  banners: BannerCarouselProps[]
}

export default function BannerCarouselGrid({
  banners,
}: BannerCarouselGridProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [activeChild, setActiveChild] = useState(0)
  const params = useParams()
  const changeChild = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        setActiveChild((a) => (a - 1 < 0 ? banners.length - 1 : a - 1))
      } else if (e.key === "ArrowRight") {
        setActiveChild((a) => (a + 1 > banners.length - 1 ? 0 : a + 1))
      }
    },
    [banners]
  )

  useEffect(() => {
    document.addEventListener("keydown", changeChild)
    return function cleanup() {
      document.removeEventListener("keydown", changeChild)
    }
  })

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="banner_carousel__container">
              <Carousel
                index={activeChild}
                autoPlay={true}
                navButtonsAlwaysVisible={false}
                navButtonsAlwaysInvisible={true}
                indicators={banners.length === 1 ? false : true}
                animation="slide"
                indicatorIconButtonProps={{
                  className: "Indicator",
                  style: {
                    color:
                      params.schemeType === "cycle_to_work" ||
                      params.schemeType === "bike_shop"
                        ? "#EAE9EE"
                        : "",
                  },
                }}
                activeIndicatorIconButtonProps={{
                  className: banners.length === 1 ? "" : "activeIndicator",
                }}
                interval={5000}
                height={isDesktop ? "13.125rem" : "9.5rem"}
                className="carouselBanner"
              >
                {banners.map((banner, index) => {
                  return (
                    <React.Fragment key={index}>
                      <BannerCarousel
                        desktopImageUrl={banner.desktopImageUrl}
                        mobileImageUrl={banner.mobileImageUrl}
                        link={banner.link}
                        title={banner.title}
                        subtitle={banner.subtitle}
                      />
                    </React.Fragment>
                  )
                })}
              </Carousel>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
