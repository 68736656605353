import { ExploreButton } from "../../lifestyle-savings/shared-styles"
import { styled, Typography, css, Box } from "@mui/material"
import { ContentGrid } from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/Content"
import FamilyPayIconImage from "./assets/FamilyPayIconImage.png"
import Childminder from "./assets/Childminder.png"
import Nursery from "./assets/Nursery.png"
import HolidayClubs from "./assets/HolidayClubs.png"
import { useTranslation } from "react-i18next"
export type items = {
  label: string
  link?: string
  __typename: string
}
export type MegaProps = {
  link?: string
  items: items[]
  setActive: (state: boolean) => void
}
const FamilyPayCard = ({ link, items, setActive }: MegaProps) => {
  const { t } = useTranslation("portalHomepage")
  const Label = items.map((item) => {
    return item.label
  })

  return (
    <>
      <div
        className="mega-menu-tab__health-and-wellbeing-image"
        style={{ textAlign: "center", width: "40%" }}
      >
        <img
          src={FamilyPayIconImage}
          alt="FamilyPayIconImage"
          className="mega-menu-tab__health-and-wellbeing-image"
          style={{ paddingBottom: "1rem" }}
        />
        <Cardlayout style={{ textAlign: "center" }}>
          {Label.includes("Nursery") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "6.25rem",
              }}
            >
              <ImageConatiner>
                <FamilyPayIcon src={Nursery} alt="NurseryImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyPay.services.nursery")}
              </TextContainer>
            </div>
          )}

          {Label.includes("Childminder") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "6.25rem",
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
              }}
            >
              <ImageConatiner>
                <FamilyPayIcon src={Childminder} alt="ChildminderImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyPay.services.childminder")}
              </TextContainer>
            </div>
          )}

          {Label.includes("Holiday Clubs") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "6.25rem",
              }}
            >
              <ImageConatiner>
                <FamilyPayIcon src={HolidayClubs} alt="HolidayClubsImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyPay.services.holidayClubs")}
              </TextContainer>
            </div>
          )}
        </Cardlayout>

        <FamilyPayButton
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            setActive(false)
          }}
          href={link}
          component={"a"}
          target="_blank"
        >
          {t("familyPay.buttonText")}
        </FamilyPayButton>
      </div>
    </>
  )
}
export default FamilyPayCard
export const FamilyPayButton = styled(ExploreButton)`
  ${({ theme }) => css`
    &&& {
      max-width: 100%;
      width: 19.7881rem;
      background-color: #6a6ba8;
      height: 3.125rem;
      z-index: 10;
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        font-size: 0.6rem;
        height: 2.125rem;
      }
      @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
        font-size: 1rem;
        height: 3.125rem;
      }
      &:hover {
        opacity: 75%;
        background-color: #6a6ba8;
      }
    }
  `}
`
export const ContentContainer = styled(ContentGrid)`
  && {
    width: 100%;
  }
`
export const ContentContainerHeader = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;

    font-size: 0.9375rem;
    line-height: 1.375rem;
    margin-bottom: 0.25rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.875rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 1.3125rem;
    }
  `}
`

export const ImageConatiner = styled(Box)`
  ${({ theme }) => css`
    max-width: 3.3912rem;
    width: 100%;
    height: 3.3912rem;
    background-color: white;
    opacity: 1;
    border-radius: 3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 3.3912rem;
      max-width: 3.3912rem;
      width: 100%;
    }
  `}
`
export const FamilyPayIcon = styled("img")`
  ${({ theme }) => css`
    width: 3rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 1.9456rem;
    }
  `}
`
export const Cardlayout = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.4rem;
    padding-bottom: 1.5rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      max-width: 20.875rem;
      width: 100%;
      padding: 0rem;
    }
  `}
`

export const TextContainer = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    font-weight: 700;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 4px;
    }
  `}
`
