import { Button, css, styled } from "@mui/material"
import { ButtonHTMLAttributes, useState } from "react"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../../utils/useIsMobileView"
import InfoTooltip from "../../shared/InfoTooltip"
import { useFormikContext } from "formik"
import GenericField from "../../shared/form/GenericField"
import { getColleagueEmailField } from "./fields"

interface ButtonStyleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean
}
const ColleagueField = () => {
  const { t, ready } = useTranslation("registration")
  const isMobile = useIsMobileView()
  const [isReferred, setIsReferred] = useState(false)
  const color = "#666666"

  const formik = useFormikContext()

  const handleClick = (referred: boolean) => {
    if (referred) {
      setIsReferred(true)
      formik.setFieldValue("isReferred", true)
    } else {
      setIsReferred(false)
      formik.setFieldValue("isReferred", false)
    }
  }

  if (!ready) {
    return null
  }
  return (
    <>
      <MainContainerBox>
        <div>
          <QuestionText sx={{ color: color }}>
            {t("registration.colleagueReferral.question")}
          </QuestionText>
        </div>
        <ButtonContainer>
          <div>
            <OptionButton
              type="button"
              sx={{ marginLeft: !isMobile ? "1.0313rem" : "0px" }}
              onClick={() => handleClick(true)}
              selected={isReferred}
              aria-label="show enter colleague email field"
            >
              {t("registration.colleagueReferral.yes")}
            </OptionButton>
          </div>
          <div>
            <OptionButton
              type="button"
              sx={{ marginLeft: "0.625rem" }}
              onClick={() => handleClick(false)}
              selected={!isReferred}
              aria-label="hide enter colleague email field"
            >
              {t("registration.colleagueReferral.no")}
            </OptionButton>
          </div>
          <div style={{ marginLeft: "0.625rem" }}>
            <InfoTooltip
              content={t("registration.colleagueReferral.tooltip")}
            />
          </div>
        </ButtonContainer>
      </MainContainerBox>

      {isReferred && (
        <GenericField
          textFieldProps={getColleagueEmailField()}
          sx={{ marginTop: "1.5rem" }}
        />
      )}
      <RequiredText sx={{ color: color }}>
        * {t("registration.colleagueReferral.required")}
      </RequiredText>
    </>
  )
}

export default ColleagueField

export const QuestionText = styled("p")`
  font-size: 1.0625rem;
  margin: 0rem;
  padding-right: 0.3125rem;
`
export const MainContainerBox = styled("div")`
  ${({ theme }) => css`
    && {
      display: block;
      align-items: center;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        display: flex;
      }
    }
  `}
`
export const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    && {
      padding-top: 0.9375rem;
      align-items: center;
      display: flex;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        padding-top: 0rem;
      }
    }
  `}
`

const OptionButton = styled(Button)<ButtonStyleProps>`
  ${({ theme, selected }) => css`
    &&&&& {
      border: ${selected
        ? `1px solid ${theme.palette.secondary.main}`
        : "1px solid #e0e0e0"};
      border-radius: 1rem;
      width: 3rem;
      font-size: 0.875rem;
      height: 1.8125rem;
      color: ${selected ? theme.palette.secondary.main : "#000000"};
      background: ${selected ? "#2792BF14" : "transparent"};
      cursor: pointer;
    }
  `}
`
export const RequiredText = styled("div")`
  ${({ theme }) => css`
    && {
      font-size: 0.625rem;
      margin-top: 1.25rem;
      margin-bottom: 1.7rem;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        font-size: 0.75rem;
      }
    }
  `}
`
