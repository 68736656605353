import { css, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link } from "@mui/material"
import { TitleHeading, SubTitleHeading } from "../shared/headingStyles"
import { useNavigate } from "react-router-dom"

export function Headers() {
  const { t, ready } = useTranslation(["regForm"])
  const navigate = useNavigate()

  if (!ready) {
    return null
  }
  return (
    <>
      <TitleHeading variant="h1">{t("regForm:regConfirm.header")}</TitleHeading>
      <AlreadyHaveAccountText sx={{ textAlign: "center" }} variant="h4">
        {t("regForm:regConfirm.subHeader")}{" "}
        <TextWithLink
          component={"button"}
          type="button"
          aria-label={"sign in here"}
          onClick={() => {
            navigate("/users/sign_in")
          }}
        >
          <strong>{t("regForm:regConfirm.signInHere")}</strong>
        </TextWithLink>
      </AlreadyHaveAccountText>
      <PersonalDetailParagraph sx={{ textAlign: "center" }} variant="h4">
        {t("regForm:regConfirm.paragraph")}
      </PersonalDetailParagraph>
    </>
  )
}
export const AlreadyHaveAccountText = styled(SubTitleHeading)`
  ${({ theme }) => css`
    && {
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        max-width: 21.3125rem;
      }
    }
  `}
`
export const PersonalDetailParagraph = styled(SubTitleHeading)`
  ${({ theme }) => css`
    && {
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        max-width: 32.625rem;
      }
    }
  `}
`
const TextWithLink = styled(Link)`
  ${({ theme }) => css`
    && {
      text-decoration: underline;
      vertical-align: top;
      font-size: 0.875rem;
      font-family: Raleway;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        font-size: 1rem;
      }
    }
  `}
` as typeof Link
