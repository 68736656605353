import { HealthAndWellbeing as WellBeingType } from "../../../../graphqGenaretedTypes"
import {
  MobileWhiteBox,
  MobileGridContainer,
  OneProposition,
} from "../../shared/SharedStyles"
import Content from "./Content"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { styled } from "@mui/material"
import ExpandableList from "../ExpandableList"

const HealthAndWellbeing = ({ data }: { data: WellBeingType }) => {
  const isMobile = useIsMobileView()
  const { usefulLinks } = data

  if (isMobile) {
    return (
      <MobileWhiteBox>
        <MobileGrid data-cy="portal-health-and-wellbeing-mobile">
          <Content data={data} />
        </MobileGrid>
      </MobileWhiteBox>
    )
  }
  return (
    <div data-cy="portal-health-and-wellbeing-desktop">
      <OneProposition>
        <Content data={data} />
      </OneProposition>
      {usefulLinks && (
        <ExpandableList
          links={usefulLinks}
          moreButtonColor="#488200"
          isExternalLink
        />
      )}
    </div>
  )
}

export default HealthAndWellbeing

const MobileGrid = styled(MobileGridContainer)`
  grid-template-rows: auto 264px auto;
`
